import { NgModule } from '@angular/core';
import { CommonModule, CurrencyPipe } from '@angular/common';
import { PaymentComponent } from './components/payment/payment.component';
import { PaymentTransactionsComponent } from './components/payment-transactions/payment-transactions.component';
import { PaymentTransactionsMasterComponent } from './components/payment-transactions-master/payment-transactions-master.component';
import { SharedModule } from '../shared/shared.module';
import { NgxJsonViewerModule } from 'ngx-json-viewer';
import { PaymentContactConfigurationComponent } from './components/payment-contact-configuration/payment-contact-configuration.component';

@NgModule({
  imports: [
    CommonModule,
    SharedModule,
    NgxJsonViewerModule
  ],
  declarations: [
    PaymentComponent,
    PaymentTransactionsComponent,
    PaymentTransactionsMasterComponent,
    PaymentContactConfigurationComponent
  ],
  exports: [
    PaymentTransactionsComponent,
    PaymentContactConfigurationComponent
  ]
})
export class PaymentModule { }
