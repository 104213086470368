import { Component, OnInit } from '@angular/core';
import { FollowupsService } from 'src/app/contacts/services/followups.service';
import { Followup } from 'src/app/shared/models/followup.model';
import { isFuture } from 'date-fns';

@Component({
  selector: 'app-followups-portlet',
  templateUrl: './followups-portlet.component.html',
  styleUrls: ['./followups-portlet.component.scss']
})
export class FollowupsPortletComponent implements OnInit {
  public followups: Followup[];
  public isFuture: Function = isFuture;

  constructor(
    private followupsService: FollowupsService
  ) {}

  ngOnInit() {
    this.followupsService.getMyFollowups().subscribe(followups => this.followups = followups);
  }
}
