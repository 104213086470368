import { NgModule } from '@angular/core';
import { SharedModule } from '../shared/shared.module';
import { CommunicationComponent } from './components/communication/communication.component';
import { CommunicationListsComponent } from './components/communication-lists/communication-lists.component';
import { CommunicationEventsComponent } from './components/communication-events/communication-events.component';
import { CommunicationPreferencesComponent } from './components/communication-preferences/communication-preferences.component';

@NgModule({
  imports: [
    SharedModule
  ],
  exports: [
    CommunicationEventsComponent,
    CommunicationPreferencesComponent
  ],
  declarations: [
    CommunicationComponent,
    CommunicationListsComponent,
    CommunicationEventsComponent,
    CommunicationPreferencesComponent
  ]
})
export class CommunicationModule { }
