import { Pipe, PipeTransform } from '@angular/core';
import { distanceInWordsToNow } from 'date-fns';
import * as deLocale from 'date-fns/locale/de';

@Pipe({
  name: 'distanceInWordsToNow'
})
export class DistanceInWordsToNowPipe implements PipeTransform {
  transform(value: Date | string | number, addSuffix: boolean = true, ...args: any[]): any {
    if (!value) {
      return '';
    }
    return distanceInWordsToNow(value, { addSuffix, locale: deLocale });
  }
}
