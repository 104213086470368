import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { DashboardComponent } from './components/dashboard/dashboard.component';
import { SharedModule } from '../shared/shared.module';
import { FollowupsPortletComponent } from './components/followups-portlet/followups-portlet.component';
import { HotleadsPortletComponent } from './components/hotleads-portlet/hotleads-portlet.component';

@NgModule({
  imports: [
    CommonModule,
    SharedModule
  ],
  declarations: [DashboardComponent, FollowupsPortletComponent, HotleadsPortletComponent]
})
export class DashboardModule { }
