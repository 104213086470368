import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { environment } from 'src/environments/environment';
import { Observable } from 'rxjs';
import { Search } from 'src/app/shared/models/search.model';
import { SearchResult } from 'src/app/shared/models/search-result.model';

@Injectable({
  providedIn: 'root'
})
export class SearchService {
  constructor(
    private httpClient: HttpClient
  ) {}

  searchContacts(query: Object, skip = 0, take = 50): Observable<SearchResult> {
    return this.httpClient.post<SearchResult>(`${environment.api.baseUrl}contacts/search?skip=${skip}&take=${take}`, query);
  }

  exportContacts(query: Object) {
    return this.httpClient.post(`${environment.api.baseUrl}contacts/search/export`, query, {
      responseType: 'blob'
    });
  }

  performSavedSearch(id: number, skip = 0, take = 50): Observable<SearchResult> {
    return this.httpClient.get<SearchResult>(`${environment.api.baseUrl}contacts/search/${id}?skip=${skip}&take=${take}`);
  }

  getSearches(): Observable<Search[]> {
    return this.httpClient.get<Search[]>(`${environment.api.baseUrl}contacts/searches`);
  }

  createSearch(search: Search): Observable<Search> {
    return this.httpClient.post<Search>(`${environment.api.baseUrl}contacts/searches`, search);
  }

  updateSearch(id: number, updateSearch: Partial<Search>) {
    return this.httpClient.put(`${environment.api.baseUrl}contacts/searches/${id}`, updateSearch);
  }

  bulkUpdateSearches(updateSearches: Partial<Search>[]) {
    return this.httpClient.put(`${environment.api.baseUrl}contacts/searches`, {
      list: updateSearches
    });
  }

  deleteSearch(id: number) {
    return this.httpClient.delete(`${environment.api.baseUrl}contacts/searches/${id}`);
  }

}
