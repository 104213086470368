import { Component, OnInit, Output, EventEmitter } from '@angular/core';
import { FormGroup, FormBuilder, Validators, AbstractControl } from '@angular/forms';
import { parse, differenceInYears } from 'date-fns';
import { union } from 'lodash';
import { ContactCountry } from 'src/app/shared/models/contact-country.model';
import { ContactInterest } from 'src/app/shared/models/contact-interest.model';
import { merge, forkJoin } from 'rxjs';
import { ContactsService } from '../../services/contacts.service';
import { ToastrService } from 'ngx-toastr';
import { isoDateStringToNgbDate } from 'src/app/shared/misc/date.functions';

@Component({
  selector: 'app-contact-main',
  templateUrl: './contact-main.component.html',
  styleUrls: ['./contact-main.component.scss']
})
export class ContactMainComponent implements OnInit {
  @Output()
  formReady = new EventEmitter<FormGroup>();

  public isLoading = true;
  public form: FormGroup;
  public constraints: any;
  private requiredFields: string[] = [];
  public birthDateRange: any;
  public availableCountries: ContactCountry[];
  public availableInterests: ContactInterest[];
  public age = '';

  constructor(
    private fb: FormBuilder,
    private toaster: ToastrService,
    private contactsService: ContactsService
  ) {}

  ngOnInit() {
    forkJoin(
      this.contactsService.getConstraints(),
      this.contactsService.getCountries(),
      this.contactsService.getInterests()
    ).subscribe(
      ([constraints, countries, interests]) => {
        this.constraints = constraints;
        this.availableCountries = countries;
        this.availableInterests = interests;
        this.afterLoading();
      },
      error => this.toaster.error(`Daten konnten nicht geladen werden: ${error.status} – ${error.statusText}.`, 'Fehler')
    );
  }

  private afterLoading() {
    this.isLoading = false;

    this.birthDateRange = {
      min: isoDateStringToNgbDate(this.constraints.birthDateRange.min),
      max: isoDateStringToNgbDate(this.constraints.birthDateRange.max)
    };

    this.form = this.fb.group({
      id: null,
      male: [null, [this.conditionalRequiredValidator.bind(this)]],
      title: [null, [this.conditionalRequiredValidator.bind(this)]],
      firstName: [null, [this.conditionalRequiredValidator.bind(this)]],
      lastName: [null, [this.conditionalRequiredValidator.bind(this)]],
      email: [null, [Validators.email, this.conditionalRequiredValidator.bind(this)]],
      street: [null, [this.conditionalRequiredValidator.bind(this)]],
      postCode: [null, [this.conditionalRequiredValidator.bind(this)]],
      city: [null, [this.conditionalRequiredValidator.bind(this)]],
      country: [null, [this.conditionalRequiredValidator.bind(this)]],
      phone: [null, [Validators.pattern(/^\+\d{6,}$/), this.conditionalRequiredValidator.bind(this)]],
      birthDate: [null, [this.conditionalRequiredValidator.bind(this)]],
      profession: [null, [this.conditionalRequiredValidator.bind(this)]],
      company: [null, [this.conditionalRequiredValidator.bind(this)]],
      companyPosition: [null, [this.conditionalRequiredValidator.bind(this)]],
      organization: [null, [this.conditionalRequiredValidator.bind(this)]],
      organizationPosition: [null, [this.conditionalRequiredValidator.bind(this)]],
      activityStatusParticipator: false,
      activityStatusDonator: false,
      activityStatusPatron: false,
      interests: [[]],
      notes: [null]
    });

    this.form.get('birthDate').valueChanges.subscribe(birthDate => this.age = this.calcAge(birthDate));

    this.calcRequiredFields();

    merge(
      this.form.get('activityStatusParticipator').valueChanges,
      this.form.get('activityStatusDonator').valueChanges,
      this.form.get('activityStatusPatron').valueChanges
    ).subscribe(() => {
      this.calcRequiredFields();

      for (const controlName of Object.keys(this.form.controls)) {
        // Dont update triggering controls
        if (['activityStatusParticipator', 'activityStatusDonator', 'activityStatusPatron'].indexOf(controlName) === -1) {
          this.form.get(controlName).updateValueAndValidity();
        }
      }
    });

    this.formReady.emit(this.form);
  }

  public isRequired(controlName: string) {
    return this.requiredFields.indexOf(controlName) !== -1;
  }

  private calcRequiredFields() {
    let requiredFields: string[];

    requiredFields = union(this.constraints.requiredFields.everyone);

    if (this.form.get('activityStatusParticipator').value) {
      requiredFields = union(requiredFields, this.constraints.requiredFields.participator);
    }

    if (this.form.get('activityStatusDonator').value) {
      requiredFields = union(requiredFields, this.constraints.requiredFields.donator);
    }

    if (this.form.get('activityStatusPatron').value) {
      requiredFields = union(requiredFields, this.constraints.requiredFields.patron);
    }

    this.requiredFields = requiredFields;
  }

  private conditionalRequiredValidator(control: AbstractControl): { [key: string]: boolean } | null {
    let isValid = true;
    let controlName: string = null;

    if (this !== undefined && control.parent !== undefined) {
      // Get control name
      for (const cn in control.parent.controls) {
        if (control.parent.controls[cn] === control) {
          controlName = cn;
        }
      }

      // Check wether control is required or not
      if (this.isRequired(controlName)) {
        isValid = Validators.required(control) === null; // required fulfilled, not empty
      } else {
        isValid = true;
      }
    }

    if (!isValid) {
      return { 'required': true };
    } else {
      return null;
    }
  }

  private calcAge(birthDate: string): string {
    let age = '';

    if (this.form.get('birthDate').valid && this.form.get('birthDate').value) {
      age = differenceInYears(new Date(), parse(birthDate)).toString();
    }

    return age;
  }
}
