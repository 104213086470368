import { Component, OnInit, Input, ViewChild, TemplateRef, ElementRef, OnChanges, Output, EventEmitter } from '@angular/core';
import { PaymentTransactionsService } from '../../services/payment-transactions.service';
import { FormBuilder, Validators, FormGroup } from '@angular/forms';
import { ToastrService } from 'ngx-toastr';
import { NgbDateStruct, NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { format, getHours, getMinutes, setMinutes, setHours } from 'date-fns';
import { isoDateStringToNgbDate } from 'src/app/shared/misc/date.functions';
import { AuthService } from 'src/app/core/services/auth.service';
import { PaymentStripeService } from '../../services/payment-stripe.service';

@Component({
  selector: 'app-payment-transactions',
  templateUrl: './payment-transactions.component.html',
  styleUrls: ['./payment-transactions.component.scss']
})
export class PaymentTransactionsComponent implements OnInit, OnChanges {
  @Input()
  public contactId: number;

  @Output()
  public donatorChange: EventEmitter<any> = new EventEmitter();

  @ViewChild('dateCellTemplate', { static: true} ) dateCellTemplate: TemplateRef<any>;
  @ViewChild('amountCellTemplate', { static: true }) amountCellTemplate: TemplateRef<any>;
  @ViewChild('contactCellTemplate', { static: true }) contactCellTemplate: TemplateRef<any>;
  @ViewChild('statusCellTemplate', { static: true }) statusCellTemplate: TemplateRef<any>;
  @ViewChild('typeCellTemplate', { static: true }) typeCellTemplate: TemplateRef<any>;

  @ViewChild('showTransactionModal', { static: true }) showTransactionModalElement: ElementRef;
  @ViewChild('addEditManualTransactionModal', { static: true }) addEditManualTransactionModalElement: ElementRef;
  @ViewChild('deleteManualTransactionModal', { static: true }) deleteManualTransactionModalElement: ElementRef;

  public paymentTransactions = [];
  public columns: any;
  public messages: any;
  public totalCount: number;
  public totalSum: number;
  public orderBy: any;
  public pageSize = 30;
  public pageNumber = -1;
  public fromDate: NgbDateStruct;
  public toDate: NgbDateStruct;
  public searchText: string;
  public transactionToShow: any;
  public editMode: boolean;
  public addEditManualTransactionForm: FormGroup;
  public canDelete: boolean;
  public manualTransactionToDeleteId: number;
  public maximumManualTransactionDate: any;
  public availableManualTransactionHours: number[];
  public availableManualTransactionMinutes: number[];
  public cardBrandsReadable: any;

  constructor(
    private paymentTransactionsService: PaymentTransactionsService,
    private paymentStripeService: PaymentStripeService,
    private modal: NgbModal,
    private fb: FormBuilder,
    private toaster: ToastrService,
    private authService: AuthService
  ) {}

  ngOnInit() {
    this.cardBrandsReadable = this.paymentStripeService.cardBrandsReadable;
    this.maximumManualTransactionDate = isoDateStringToNgbDate(format(new Date()));
    this.availableManualTransactionHours = Array.from(Array(24).keys());
    this.availableManualTransactionMinutes = [0, 5, 10, 15, 20, 25, 30, 35, 40, 45, 50, 55];

    this.setupTable();

    if (this.pageNumber < 0) {
      // if onChanges was not triggered (no contactId), fetch init data here
      this.resetFilter();
    }
  }

  ngOnChanges() {
    this.resetFilter();
  }

  setupTable() {
    this.columns = [
      {
        prop: 'date',
        name: 'Datum',
        flexGrow: 0,
        minWidth: 150,
        cellTemplate: this.dateCellTemplate,
        sortable: false
      }, {
        prop: 'amount',
        name: 'Betrag',
        flexGrow: 0,
        minWidth: 100,
        cellClass: 'text-right',
        headerClass: 'text-right',
        cellTemplate: this.amountCellTemplate,
        sortable: false
      }, {
        prop: 'text',
        name: 'Text',
        flexGrow: 1,
        sortable: false
      }
    ];

    if (!this.contactId) {
      this.columns.push({
        prop: 'contact',
        name: 'Kontakt',
        flexGrow: 0.2,
        sortable: false,
        cellTemplate: this.contactCellTemplate,
      });
    }

    this.columns = [
      ...this.columns,
      {
        prop: 'status',
        name: 'Status',
        flexGrow: 0.1,
        minWidth: 75,
        cellTemplate: this.statusCellTemplate,
        sortable: false
      }, {
        prop: 'provider.name',
        name: 'System',
        flexGrow: 0.1,
        minWidth: 75,
        sortable: false
      }, {
        prop: 'type',
        name: 'Art',
        flexGrow: 0.1,
        minWidth: 75,
        cellTemplate: this.typeCellTemplate,
        sortable: false
      }
    ];

    this.messages = {
      emptyMessage: 'Keine Daten vorhanden.',
      totalMessage: 'Transaktionen(en)'
    };
  }

  setPage(pageInfo: any) {
    this.pageNumber = pageInfo.offset;

    this.paymentTransactionsService.getTransactions(this.filter, this.pageNumber * this.pageSize, this.pageSize).subscribe(
      (result: any) => {
        this.paymentTransactions = result.transactions;
        this.totalCount = result.count;
        this.totalSum = result.sum;
      },
      error => this.toaster.error(`Transaktionen konnten nicht geladen werden: ${error.status} – ${error.statusText}.`, 'Fehler')
    );
  }

  get filter() {
    return {
      contactId: this.contactId,
      minDate: this.fromDate,
      maxDate: this.toDate,
      text: this.searchText,
    };
  }

  applyFilter() {
    this.setPage({ offset: 0 });
  }

  resetFilter() {
    this.fromDate = this.toDate = this.searchText = undefined;
    this.setPage({ offset: 0 });
  }

  onActivate(event) {
    if (event.type === 'click') {
      event.cellElement.blur();
      if (event.row.provider.key !== 'manual') {
        this.showTransactionModal(event.row);
      } else if (this.contactId) {
        this.openAddEditManualTransactionModal(event.row);
      }
    }
  }

  showTransactionModal(transaction) {
    this.transactionToShow = transaction;
    this.modal.open(this.showTransactionModalElement, {
      backdrop: 'static',
      scrollable: true
    }).result.then(result => {
      // Close
    }, reason => {
      // Dismiss
    });
  }

  /**
   * Manual transactions
   */

  openAddEditManualTransactionModal(transaction: any) {
    if (transaction && transaction.status === 'canceled') {
      return;
    }

    const now = new Date();
    const initialManualTransactionIsoDate = format(now, 'YYYY-MM-DD');

    this.addEditManualTransactionForm = this.fb.group({
      id: [transaction ? transaction.id : undefined],
      date: [initialManualTransactionIsoDate, [Validators.required]],
      hour: [getHours(now)],
      minutes: [getMinutes(now) - getMinutes(now) % 5],
      amount: [0, [Validators.required, Validators.min(0.01)]],
      text: [null, []]
    });

    if (transaction) {
      // Edit
      this.editMode = true;

      this.addEditManualTransactionForm.patchValue({
        ...transaction,
        amount: transaction.amount / 100,
        date: format(transaction.date, 'YYYY-MM-DD'),
        hour: getHours(transaction.date),
        minutes: getMinutes(transaction.date)
      });

      if (!this.authService.isMinRole('manager')) {
        this.addEditManualTransactionForm.disable();
      }
    } else {
      // Add
      this.editMode = false;
    }

    this.modal.open(this.addEditManualTransactionModalElement, { backdrop: 'static' }).result.then(result => {
      // Close
    }, reason => {
      // Dismiss
    });
  }

  getDateFromManualTransactionForm(): Date {
    const date = this.addEditManualTransactionForm.get('date').value;
    const hour = this.addEditManualTransactionForm.get('hour').value;
    const minutes = this.addEditManualTransactionForm.get('minutes').value;

    return setMinutes(setHours(date, hour), minutes);
  }

  saveManualTransaction(closeModal: Function) {
    const transactionFormData = this.addEditManualTransactionForm.value;

    const transaction: Partial<any> = {
      id: transactionFormData.id,
      contactId: this.contactId,
      date: format(this.getDateFromManualTransactionForm()),
      amount: Math.round(transactionFormData.amount * 100),
      text: transactionFormData.text
    };

    let observable;
    if (!transaction.id) {
      observable = this.paymentTransactionsService.createTransaction(transaction);
    } else {
      observable = this.paymentTransactionsService.updateTransaction(transaction);
    }

    observable.subscribe(() => {
      closeModal();
      this.toaster.success('Transaktion wurde gespeichert.');
      this.donatorChange.emit(true);
      this.resetFilter();
    }, error => this.toaster.error(`Transaktion konnte nicht gespeichert werden: ${error.status} – ${error.statusText}.`, 'Fehler')); // tslint:disable
  }

  openDeleteManualTransactionModal(manualTransactionToDeleteId: number) {
    this.manualTransactionToDeleteId = manualTransactionToDeleteId;

    this.modal.open(this.deleteManualTransactionModalElement, { backdrop: 'static' }).result.then(result => {
      // Close
      this.manualTransactionToDeleteId = undefined;
    }, reason => {
      // Dismiss
      this.manualTransactionToDeleteId = undefined;
    });
  }

  deleteManualTransaction(closeModal: Function) {
    this.paymentTransactionsService.updateTransaction({
      id: this.manualTransactionToDeleteId,
      status: 'canceled'
    }).subscribe(() => {
      closeModal();
      this.toaster.success('Transaktion wurde gelöscht.');
      this.resetFilter();
    }, error => this.toaster.error(`Transaktion konnte nicht gelöscht werden: ${error.status} – ${error.statusText}.`, 'Fehler'));
  }
}
