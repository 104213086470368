import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})
export class PaymentStripeService {
  readonly cardBrandsReadable = {
    'amex': 'American Express',
    'diners': 'Diners Club',
    'discover': 'Discover',
    'jcb': 'JCB',
    'mastercard': 'Mastercard',
    'unionpay': 'UnionPay',
    'visa': 'VISA',
    'unknown': 'Unbekannt'
  };

  constructor(
    private httpClient: HttpClient
  ) {}

  getPaymentMethodsSources(contactId: number): Observable<any> {
    return this.httpClient.get<any>(`${environment.api.baseUrl}payment/stripe/contacts/${contactId}/payment-methods-sources`);
  }

  getSubscriptions(contactId: number): Observable<any> {
    return this.httpClient.get<any>(`${environment.api.baseUrl}payment/stripe/contacts/${contactId}/subscriptions`);
  }

  deletePaymentMethod(contactId: number, paymentMethodId: string): Observable<any> {
    return this.httpClient.delete<any>(`${environment.api.baseUrl}payment/stripe/contacts/${contactId}/payment-methods/${paymentMethodId}`);
  }

  deletePaymentSource(contactId: number, sourceId: string): Observable<any> {
    return this.httpClient.delete<any>(`${environment.api.baseUrl}payment/stripe/contacts/${contactId}/sources/${sourceId}`);
  }

  createPaymentSource(contactId: number, paymentSource: any): Observable<any> {
    return this.httpClient.post<any>(`${environment.api.baseUrl}payment/stripe/contacts/${contactId}/sources`, paymentSource);
  }

  createSubscription(contactId: number, subscription: string): Observable<any> {
    return this.httpClient.post<any>(`${environment.api.baseUrl}payment/stripe/contacts/${contactId}/subscriptions`, subscription);
  }

  updateSubscription(contactId: number, subscriptionId: string, subscription: any): Observable<any> {
    return this.httpClient.put<any>(
      `${environment.api.baseUrl}payment/stripe/contacts/${contactId}/subscriptions/${subscriptionId}`,
      subscription
    );
  }

  deleteSubscription(contactId: number, subscriptionId: string): Observable<any> {
    return this.httpClient.put<any>(
      `${environment.api.baseUrl}payment/stripe/contacts/${contactId}/subscriptions/${subscriptionId}/delete`,
      {}
    );
  }
}
