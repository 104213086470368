import { NgbDateParserFormatter, NgbDateStruct } from '@ng-bootstrap/ng-bootstrap';
import { Injectable } from '@angular/core';
import { ngbDateToIsoDateString } from './date.functions';
import { formatDate } from '@angular/common';

@Injectable()
export class NgbDateLocaleParserFormatter extends NgbDateParserFormatter {
  parse(localeDateString: string): NgbDateStruct {
    if (localeDateString) {
      let year: number, month: number, day: number;
      [day, month, year] = localeDateString.split('.').map(x => +x);

      if (Number.isInteger(day) && Number.isInteger(month) && Number.isInteger(year)) {
        return { year, month, day };
      }
    }

    return null;
  }

  format(ngbDate: NgbDateStruct): string {
    if (ngbDate) {
      return formatDate(ngbDateToIsoDateString(ngbDate), 'mediumDate', 'de');
    }

    return null;
  }
}
