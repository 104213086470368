import { Injectable } from '@angular/core';
import { HttpClient, HttpParams } from '@angular/common/http';
import { Observable } from 'rxjs';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})
export class PaymentTransactionsService {
  constructor(
    private httpClient: HttpClient
  ) {}

  getTransactions(query?: any, skip?: number, take?: number): Observable<any[]> {
    let url;

    if (query && query.contactId) {
      url = `${environment.api.baseUrl}payment/contacts/${query.contactId}/transactions/search`;
    } else {
      url = `${environment.api.baseUrl}payment/transactions`;
    }

    let params = new HttpParams();

    if (typeof skip !== 'undefined') {
      params = params.append('skip', skip.toString());
    }

    if (typeof take !== 'undefined') {
      params = params.append('take', take.toString());
    }
    return this.httpClient.post<any[]>(url, query, { params });
  }

  createTransaction(transaction: any): Observable<any> {
    return this.httpClient.post<any>(`${environment.api.baseUrl}payment/contacts/${transaction.contactId}/transactions`, transaction);
  }

  updateTransaction(transaction: any): Observable<any> {
    return this.httpClient.put<any>(
      `${environment.api.baseUrl}payment/transactions/${transaction.id}`, transaction
    );
  }
}
