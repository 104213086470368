import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';

import { AuthGuard } from './core/guards/auth.guard';
import { LoginComponent } from './core/components/login/login.component';
import { AppLayoutComponent } from './core/components/app-layout/app-layout.component';
import { DashboardComponent } from './dashboard/components/dashboard/dashboard.component';
import { ContactDetailComponent } from './contacts/components/contact-detail/contact-detail.component';
import { ContactsSearchComponent } from './contacts/components/contacts-search/contacts-search.component';
import { UsersComponent } from './system/components/users/users.component';
import { ContactCreateComponent } from './contacts/components/contact-create/contact-create.component';
import { ContactDetailMasterComponent } from './contacts/components/contact-detail-master/contact-detail-master.component';
import { CommunicationListsComponent } from './communication/components/communication-lists/communication-lists.component';
import { CommunicationComponent } from './communication/components/communication/communication.component';
import { PaymentComponent } from './payment/components/payment/payment.component';
import { PaymentTransactionsMasterComponent } from './payment/components/payment-transactions-master/payment-transactions-master.component';

const routes: Routes = [
  { path: 'login', component: LoginComponent },
  { path: '', canActivate: [AuthGuard], component: AppLayoutComponent, children: [
    { path: '', redirectTo: '/dashboard', pathMatch: 'full' },
    { path: 'dashboard', component: DashboardComponent },
    { path: 'contacts', children: [
      { path: 'detail', component: ContactDetailMasterComponent, children: [
        { path: ':id', component: ContactDetailComponent }
      ] },
      { path: 'create', component: ContactCreateComponent },
      { path: 'search', component: ContactsSearchComponent }
    ] },
    { path: 'communication', component: CommunicationComponent, children: [
      { path: 'lists', component: CommunicationListsComponent }
    ] },
    { path: 'payment', component: PaymentComponent, children: [
      { path: 'transactions', component: PaymentTransactionsMasterComponent }
    ] },
    { path: 'system/users', canActivate: [AuthGuard], component: UsersComponent, data: { minRole: 'manager' } }
  ] }
];

@NgModule({
  imports: [ RouterModule.forRoot(routes) ],
  exports: [ RouterModule ]
})
export class AppRoutingModule { }
