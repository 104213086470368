import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { environment } from '../../../environments/environment';
import { Observable, of, concat, pipe } from 'rxjs';
import { map, tap, catchError, mergeMap, concatMap } from 'rxjs/operators';
import { User } from '../../shared/models/user.model';
import { Role } from '../../shared/models/role.model';
import { UsersService } from '../../system/services/users.service';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { ChangePasswordComponent } from '../components/change-password/change-password.component';

@Injectable({
  providedIn: 'root'
})
export class AuthService {
  public user: User = null;
  public roles: Role[];
  public redirectUrl: string;

  constructor(
    private httpClient: HttpClient,
    private usersService: UsersService,
    private modal: NgbModal
  ) {}

  login(email: string, password: string): Observable<number> {
    return this.httpClient.post<User>(`${environment.api.baseUrl}auth/login`, {
      email, password
    }).pipe(
      concatMap(user => {
        this.user = user;
        return this.usersService.getRoles();
      }),
      map(roles => {
        this.roles = roles;
        return 200;
      }),
      catchError(error => {
        return of(error.status);
      })
    );
  }

  logout(): Observable<any> {
    return this.httpClient.get(`${environment.api.baseUrl}auth/logout`)
      .pipe(tap(() => {
        this.user = null;
        this.roles = null;
      })
    );
  }

  impersonate(userId: number) {
    return this.httpClient.get(`${environment.api.baseUrl}auth/impersonate/${userId}`);   
  }

  deimpersonate() {
    return this.httpClient.get(`${environment.api.baseUrl}auth/deimpersonate`);   
  }

  changePassword(oldPassword: string, newPassword: string): Observable<any> {
    return this.httpClient.put(`${environment.api.baseUrl}auth/change-password`, {
      oldPassword, newPassword
    });
  }

  openChangePasswordModal(userId?: number, userEmail?: string) {
    const modalRef = this.modal.open(ChangePasswordComponent);

    modalRef.componentInstance.userId = userId;
    modalRef.componentInstance.userEmail = userEmail;

    return modalRef.result;
  }

  getSession(): Observable<boolean> {
    return this.httpClient.get<User>(`${environment.api.baseUrl}auth/session`).pipe(
      concatMap(user => {
        this.user = user;
        return this.usersService.getRoles();
      }),
      map(roles => {
        this.roles = roles;
        return true;
      }),
      catchError(error => {
        return of(false);
      })
    );
  }

  isMinRole(minRoleKey: string): boolean {
    let isMinRole = false;

    if (this.roles) {
      const minRole = this.roles.find(role => role.key === minRoleKey);

      if (!minRole) {
        // minRole not found (eg. no permission to see role, programmatic error)
        return false;
      }

      isMinRole = this.user.role.accessLevel >= minRole.accessLevel;
    }

    return isMinRole;
  }
}
