import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';

@Component({
  selector: 'app-contact-payments',
  templateUrl: './contact-payments.component.html',
  styleUrls: ['./contact-payments.component.scss']
})
export class ContactPaymentsComponent implements OnInit {
  @Input()
  contactId: number;

  @Input()
  contactName: string;

  @Input()
  contactEmail: string;

  @Output()
  membershipChange: EventEmitter<any> = new EventEmitter();

  @Output()
  donatorChange: EventEmitter<any> = new EventEmitter();

  constructor() {}

  ngOnInit() {
  }
}
