import { Injectable, EventEmitter } from '@angular/core';
import { Observable, of } from 'rxjs';
import { Contact } from 'src/app/shared/models/contact.model';
import { environment } from 'src/environments/environment';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { ContactCountry } from 'src/app/shared/models/contact-country.model';
import { ContactInterest } from 'src/app/shared/models/contact-interest.model';
import { tap } from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})
export class ContactsService {
  public contactDeleted: EventEmitter<number>;
  private cache;

  constructor(
    private httpClient: HttpClient
  ) {
    this.contactDeleted = new EventEmitter();

    this.cache = {
      constraints: null,
      countries: null
    };
  }

  getContact(contactId: number): Observable<Contact> {
    return this.httpClient.get<Contact>(`${environment.api.baseUrl}contacts/${contactId}`);
  }

  getContacts(searchText: string, skip?: number, take?: number): Observable<Contact[]> {
    let url = `${environment.api.baseUrl}contacts/?searchText=${searchText}`;

    if (skip) {
      url += `&skip=${skip}`;
    }

    if (take) {
      url += `&take=${take}`;
    }

    return this.httpClient.get<Contact[]>(url);
  }

  createContact(partialContact: Partial<Contact>): Observable<Contact> {
    return this.httpClient.post<Contact>(`${environment.api.baseUrl}contacts`, partialContact);
  }

  updateContact(partialContact: Partial<Contact>) {
    return this.httpClient.put<Contact>(`${environment.api.baseUrl}contacts/${partialContact.id}`, partialContact);
  }

  deleteContact(contactId: number) {
    return this.httpClient.delete(`${environment.api.baseUrl}contacts/${contactId}`).pipe(
      tap(() => this.contactDeleted.emit(contactId))
    );
  }

  getHotleads(options: {
    expiredOnly: boolean;
    disableLoadingSpinner: boolean;
  } = {
      expiredOnly: false,
      disableLoadingSpinner: true
    }): Observable<Contact[]> {
    let headers: HttpHeaders = new HttpHeaders();

    if (options.disableLoadingSpinner) {
      headers = headers.append('Disable-Loading-Spinner', '');
    }

    return this.httpClient.get<Contact[]>(
      `${environment.api.baseUrl}contacts/hotleads?expiredOnly=${options.expiredOnly}`,
      { headers }
    );
  }

  resetHotlead(contactId: number): Observable<any> {
    return this.httpClient.get<any>(`${environment.api.baseUrl}contacts/${contactId}/reset-hotlead`);
  }

  banUser(contactId: number): Observable<any> {
    return this.httpClient.get<any>(`${environment.api.baseUrl}contacts/${contactId}/ban`);
  }

  unbanUser(contactId: number): Observable<any> {
    return this.httpClient.get<any>(`${environment.api.baseUrl}contacts/${contactId}/unban`);
  }

  getConstraints(): Observable<any> {
    if (this.cache.constraints) {
      return of(this.cache.constraints);
    } else {
      return this.httpClient.get<any>(`${environment.api.baseUrl}contacts/constraints`).pipe(
        tap(constraints => this.cache.constraints = constraints)
      );
    }
  }

  getCountries(): Observable<ContactCountry[]> {
    if (this.cache.countries) {
      return of(this.cache.countries);
    } else {
      return this.httpClient.get<ContactCountry[]>(`${environment.api.baseUrl}contacts/countries`).pipe(
        tap(countries => this.cache.countries = countries)
      );
    }
  }

  getInterests(): Observable<ContactInterest[]> {
    return this.httpClient.get<ContactInterest[]>(`${environment.api.baseUrl}contacts/interests`);
  }
}
