import { Component, OnInit, Input } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { Validators, FormGroup, FormBuilder, FormControl } from '@angular/forms';
import { passwordMatchValidator } from '../../../shared/misc/password-match.validator';
import { UsersService } from '../../../system/services/users.service';
import { ToastrService } from 'ngx-toastr';
import { AuthService } from '../../services/auth.service';

@Component({
  selector: 'app-change-password',
  templateUrl: './change-password.component.html',
  styleUrls: ['./change-password.component.scss']
})
export class ChangePasswordComponent implements OnInit {
  @Input() userId?: number;
  @Input() userEmail?: string;

  public changePasswordForm: FormGroup;
  public selfChange = false;

  constructor(
    private activeModal: NgbActiveModal,
    private fb: FormBuilder,
    private toaster: ToastrService,
    private usersService: UsersService,
    private authService: AuthService
  ) {}

  ngOnInit() {
    if (!this.userId) {
      this.userId = this.authService.user.id;
      this.userEmail = this.authService.user.email;
      this.selfChange = true;
    }

    this.changePasswordForm = this.fb.group({
      email: [{ value: this.userEmail, disabled: true }],
      password1: [null, [Validators.required, Validators.minLength(8)]],
      password2: [null, [Validators.required, Validators.minLength(8)]],
    }, { validator: passwordMatchValidator('password1', 'password2') });

    if (this.selfChange) {
      this.changePasswordForm.addControl('oldPassword', new FormControl(null, [Validators.required]));
    }
  }

  changePassword() {
    const formValues = this.changePasswordForm.value;
    let observable;

    if (this.selfChange) {
      observable = this.authService.changePassword(
        formValues.oldPassword,
        formValues.password1
      );
    } else {
      observable = this.usersService.saveUser({
        id: this.userId,
        password: formValues.password1
      });
    }

    observable.subscribe(result => {
      this.toaster.success('Passwort wurde geändert.');
      this.activeModal.close();
    }, error => {
      if (this.selfChange && error.status === 422) {
        this.changePasswordForm.get('oldPassword').setErrors({
          invalidPassword: true
        });
      } else {
        this.toaster.error(`Passwort konnte nicht geändert werden: ${error.status} – ${error.statusText}.`, 'Fehler');
      }
    });
  }

  dismiss() {
    this.activeModal.dismiss();
  }
}
