import { Component, OnInit, Input } from '@angular/core';
import { CommunicationPreferencesService } from '../../services/communication-preferences.service';
import { forkJoin } from 'rxjs';
import { ToastrService } from 'ngx-toastr';
import { FormBuilder, FormGroup, FormArray } from '@angular/forms';
import { find } from 'rxjs/operators';

@Component({
  selector: 'app-communication-preferences',
  templateUrl: './communication-preferences.component.html',
  styleUrls: ['./communication-preferences.component.scss']
})
export class CommunicationPreferencesComponent implements OnInit {
  @Input()
  public contactId: number;

  public preferencesForm: FormGroup = new FormGroup({});
  public channels = [];
  public lists = [];

  constructor(
    private communicationPreferencesService: CommunicationPreferencesService,
    private fb: FormBuilder,
    private toaster: ToastrService
  ) {}

  ngOnInit() {
    this.preferencesForm = this.fb.group({
      channels: this.fb.array([]),
      lists: this.fb.array([]),
    });

    forkJoin(
      this.communicationPreferencesService.getProfile(this.contactId),
      this.communicationPreferencesService.getListsByContact(this.contactId)
    ).subscribe(
      ([profile, lists]) => this.fillForm(profile, lists),
      error => this.toaster.error(`Daten konnten nicht geladen werden: ${error.status} – ${error.statusText}.`, 'Fehler')
    );
  }

  get channelArray(): FormArray {
    return this.preferencesForm.get('channels') as FormArray;
  }

  get listArray(): FormArray {
    return this.preferencesForm.get('lists') as FormArray;
  }

  private fillForm(profile, lists) {
    // Channels
    for (const channel of profile) {
      this.channels.push(channel);
      const channelControl = this.fb.control(channel.active);
      channelControl.valueChanges.subscribe(value => this.changeChannel(channel.key, value));
      this.channelArray.push(channelControl);
    }

    // Lists
    this.lists = lists;
    for (const list of lists) {
      const listControl = this.fb.control(list.subscribed);
      listControl.valueChanges.subscribe(value => this.changeList(list.id, value));
      this.listArray.push(listControl);
    }
  }

  changeChannel(channelKey, value) {
    const updateProfile = {};
    updateProfile[channelKey] = value;

    this.communicationPreferencesService.updateCommunicationProfile(this.contactId, updateProfile).subscribe(
      profile => {
        this.toaster.success('Einstellung für Kommunikationskanal wurde gespeichert.');
      },
      error =>  {
        const i = this.channels.findIndex(channel => channel.key === channelKey);
        this.channelArray.at(i).setValue(!value, { emitEvent: false });

        // tslint:disable-next-line:max-line-length
        this.toaster.error(`Einstellung für Kommunikationskanal konnte nicht gespeichert werden: ${error.status} – ${error.statusText}.`, 'Fehler');
      }
    );
  }

  changeList(listId, value) {
    this.communicationPreferencesService.updateCommunicationListSubscription(this.contactId, listId, value).subscribe(
      () => {
        this.toaster.success('Einstellung für Verteilerliste wurde gespeichert.');
      },
      error => {
        const i = this.lists.findIndex(list => list.id === listId);
        this.listArray.at(i).setValue(!value, { emitEvent: false });

        // tslint:disable-next-line:max-line-length
        this.toaster.error(`Einstellung für Verteilerliste konnte nicht gespeichert werden: ${error.status} – ${error.statusText}.`, 'Fehler');
      }
    );
  }
}
