import { Component, OnInit, Output, EventEmitter } from '@angular/core';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { ToastrService } from 'ngx-toastr';
import { ContactsService } from '../../services/contacts.service';
import { forkJoin } from 'rxjs';
import { ContactCountry } from 'src/app/shared/models/contact-country.model';
import { ContactInterest } from 'src/app/shared/models/contact-interest.model';
import { CommunicationEventsService } from 'src/app/communication/services/communication-events.service';
import { CommunicationChannel } from 'src/app/shared/models/communication-event-type.model';

@Component({
  selector: 'app-contacts-search-form',
  templateUrl: './contacts-search-form.component.html',
  styleUrls: ['./contacts-search-form.component.scss']
})
export class ContactsSearchFormComponent implements OnInit {
  @Output()
  formReady = new EventEmitter<FormGroup>();

  public isLoading = true;
  public form: FormGroup;
  public availableCountries: ContactCountry[];
  public availableInterests: ContactInterest[];
  public availableCommunicationChannels: CommunicationChannel[];

  constructor(
    private fb: FormBuilder,
    private toaster: ToastrService,
    private contactsService: ContactsService,
    private communicationsEventsService: CommunicationEventsService
  ) {}

  ngOnInit() {
    forkJoin(
      this.contactsService.getCountries(),
      this.contactsService.getInterests(),
      this.communicationsEventsService.getChannels()
    ).subscribe(
      ([countries, interests, channels]) => {
        this.availableCountries = [{ key: null, name: 'Unbekannt' }, ...countries];
        this.availableInterests = interests;
        this.availableCommunicationChannels = channels;
        this.afterLoading();
      },
      error => this.toaster.error(`Daten konnten nicht geladen werden: ${error.status} – ${error.statusText}.`, 'Fehler')
    );
  }

  private afterLoading() {
    this.isLoading = false;

    this.form = this.fb.group({
      male: [false],
      female: [false],
      unknownSex: [false],
      title: [null],
      firstName: [null],
      lastName: [null],
      email: [null],
      street: [null],
      postCode: [null],
      city: [null],
      countries: [[]],
      phone: [null],
      minAge: [null, [Validators.min(6), Validators.max(120)]],
      maxAge: [null, [Validators.min(6), Validators.max(120)]],
      profession: [null],
      company: [null],
      companyPosition: [null],
      organization: [null],
      organizationPosition: [null],
      hasActivityStatusParticipator: [false],
      activityStatusParticipator: [null],
      hasActivityStatusDonator: [false],
      activityStatusDonator: [null],
      hasActivityStatusPatron: [false],
      activityStatusPatron: [null],
      interests: [[]],
      hasHotlead: [false],
      hotlead: [null],
      communicationChannels: [null],
      minPaymentAmount: [null, [Validators.min(0)]],
      maxPaymentAmount: [null, [Validators.min(0)]]
    }, {
      validator: Validators.compose([
        this.ageValidator.bind(this),
        this.paymentAmountValidator.bind(this)
      ])
    });

    // In current BE implementation unknown sex cant coexist with male or/and female (no or-search implemented)

    this.form.get('male').valueChanges.subscribe(male => {
      this.form.get('unknownSex').setValue(male && this.form.get('female').value, { emitEvent: false });
    });

    this.form.get('female').valueChanges.subscribe(female => {
      this.form.get('unknownSex').setValue(female && this.form.get('male').value, { emitEvent: false });
    });

    this.form.get('unknownSex').valueChanges.subscribe(unknownSex => {
      this.form.get('male').setValue(false, { emitEvent: false });
      this.form.get('female').setValue(false, { emitEvent: false });
    });

    // Enable/disable radio button groups on checkbox value

    for (const radioGroupName of [
      'activityStatusParticipator',
      'activityStatusDonator',
      'activityStatusPatron',
      'hotlead'
    ]) {
      // Init
      this.form.get(radioGroupName).disable();
    }

    this.form.get('hasActivityStatusParticipator').valueChanges.subscribe(hasActivityStatusParticipator =>
      this.switchRadio('activityStatusParticipator', hasActivityStatusParticipator)
    );

    this.form.get('hasActivityStatusDonator').valueChanges.subscribe(hasActivityStatusDonator =>
      this.switchRadio('activityStatusDonator', hasActivityStatusDonator)
    );

    this.form.get('hasActivityStatusPatron').valueChanges.subscribe(hasActivityStatusPatron =>
      this.switchRadio('activityStatusPatron', hasActivityStatusPatron)
    );

    this.form.get('hasHotlead').valueChanges.subscribe(hasHotlead =>
      this.switchRadio('hotlead', hasHotlead)
    );

    this.formReady.emit(this.form);
  }

  private switchRadio(fieldName: string, on: boolean) {
    const f = this.form.get(fieldName);

    if (on) {
      f.setValue(true);
      f.enable();
    } else {
      f.setValue(null);
      f.disable();
    }
  }

  // Validators

  ageValidator(formGroup: FormGroup): { [key: string]: boolean } | null {
    const minAgeField = formGroup.get('minAge');
    const maxAgeField = formGroup.get('maxAge');

    return this.abstractMinMaxIntValidator(
      minAgeField.value, maxAgeField.value, 'minAgeGreaterThanMaxAge'
    );
  }

  paymentAmountValidator(formGroup: FormGroup) {
    const minPaymentAmountField = formGroup.get('minPaymentAmount');
    const maxPaymentAmountField = formGroup.get('maxPaymentAmount');

    return this.abstractMinMaxIntValidator(
      minPaymentAmountField.value, maxPaymentAmountField.value,
      'minPaymentAmountGreaterThanMaxPaymentAmount'
    );
  }

  abstractMinMaxIntValidator(minValue, maxValue, errorName: string) {
    const min = minValue === null ? null : parseInt(minValue, 10);
    const max = maxValue === null ? null : parseInt(maxValue, 10);

    let returnValue;

    if (min !== null && max !== null) {
      if (min > max) {
        returnValue = {};
        returnValue[errorName] = true;
        return returnValue;
      }
    }

    return null;
  }
}
