import { Injectable } from '@angular/core';
import { HttpEvent, HttpInterceptor, HttpHandler, HttpRequest, HttpErrorResponse } from '@angular/common/http';
import { Observable, throwError } from 'rxjs';
import { tap } from 'rxjs/operators';
import { Router } from '@angular/router';
import { environment } from 'src/environments/environment';

@Injectable()
export class AuthInterceptor implements HttpInterceptor {
  constructor(
    private router: Router
  ) {}

  intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    const req = request.clone({ withCredentials: false });

    return next.handle(req).pipe(tap(
      () => {
        // Response
        return;
      }, error => {
        if (error instanceof HttpErrorResponse) {
          if (error.status === 401) {
            if (req.url === `${environment.api.baseUrl}auth/login`) {
              // Login failure, HTTP status is not a necessity to redir (already at login mask)
              return;
            } else {
              // Secure logout
              window.location.replace('/login');
            }
          } else {
            return throwError(error);
          }
        }
      }
    ));
  }
}
