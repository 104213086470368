import { Role } from './role.model';

export enum UserState {
  NEEDS_VERIFICATION = 'NEEDS_VERIFICATION',
  VERIFIED = 'VERIFIED',
  DELETED = 'DELETED',
  BANNED = 'BANNED'
}

export class User {
  id: number;
  email: string;
  firstName: string;
  lastName: string;
  password?: string;
  male = true;
  role: Role;
  state: UserState;
  // Metadata
  createdAt: Date;
  createdFromIP: string;
  createdBy: User;
  updatedAt: Date;
  updatedFromIP: string;
  updatedBy: User;
  // Impersonation
  realUser?: User;
}
