import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { environment } from '../../../environments/environment';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class CommunicationPreferencesService {
  constructor(
    private httpClient: HttpClient
  ) {}

  getProfile(contactId: number): Observable<any> {
    return this.httpClient.get<any[]>(`${environment.api.baseUrl}communication/profiles/${contactId}`);
  }

  updateCommunicationProfile(contactId: number, updateProfile: any): Observable<any> {
    return this.httpClient.put<any[]>(`${environment.api.baseUrl}communication/profiles/${contactId}`, updateProfile);
  }

  getListsByContact(contactId: number): Observable<any> {
    return this.httpClient.get<any[]>(`${environment.api.baseUrl}communication/lists/contacts/${contactId}`);
  }

  updateCommunicationListSubscription(contactId: number, listId: number, subscribe: boolean): Observable<any> {
    const subscription = subscribe ? 'subscribe' : 'unsubscribe';
    return this.httpClient.get<any[]>(`${environment.api.baseUrl}communication/lists/contacts/${contactId}/${subscription}/${listId}`);
  }
}
