import { Component, OnInit, Input, OnChanges, Output, EventEmitter } from '@angular/core';
import { ContactsService } from '../../services/contacts.service';
import { Contact } from 'src/app/shared/models/contact.model';
import { ToastrService } from 'ngx-toastr';
import { Router } from '@angular/router';
import { ConfirmDialogService } from 'src/app/shared/services/confirm-dialog.service';

@Component({
  selector: 'app-contact-system',
  templateUrl: './contact-system.component.html',
  styleUrls: ['./contact-system.component.scss']
})
export class ContactSystemComponent implements OnInit, OnChanges {
  @Input()
  public contact: Contact;

  @Output()
  userChange: EventEmitter<any> = new EventEmitter();

  constructor(
    private contactsService: ContactsService,
    private confirmDialog: ConfirmDialogService,
    private toaster: ToastrService,
    private router: Router
  ) {}

  ngOnInit() {
  }

  ngOnChanges() {
  }

  async deleteContact() {
    const confirm = await this.confirmDialog.confirm({
      message: 'Wollen Sie diesen Kontakt sicher löschen?',
      confirmText: 'Löschen',
      confirmString: {
        label: 'Bitte geben Sie zur Bestätigung das Wort "LÖSCHEN" ein',
        string: 'LÖSCHEN'
      },
      isDangerous: true
    });

    if (confirm) {
      this.contactsService.deleteContact(this.contact.id).subscribe(
        () => {
          // Delete successful
          this.router.navigate(['/contacts/detail']);
        },
        error => {
          // Error
          if (error.status === 400 && error.error.message === 'StripeSubscriptionsExisting') {
            this.toaster.warning(
              'Kontakt konnte nicht gelöscht werden, da noch Stripe-Dauerzahlungen eingerichtet sind.'
              + ' Bitte entfernen Sie diese zuerst und versuchen Sie dann erneut zu löschen.',
              'Warnung', { disableTimeOut: true }
            );
          } else {
            this.toaster.error(`Kontakt konnte nicht gelöscht werden: ${error.status} – ${error.statusText}.`, 'Fehler');
          }
        }
      );
    }
  }

  async banUser() {
    await this.banOrUnbanUser('ban');
  }

  async unbanUser() {
    await this.banOrUnbanUser('unban');
  }

  private async banOrUnbanUser(mode: string) {
    const confirm = await this.confirmDialog.confirm({
      message: `Sind Sie sicher, dass Sie das Benutzerkonto dieses Kontakts ${mode === 'ban' ? 'sperren' : 'entsperren'} möchten?`,
      confirmText: `${mode === 'ban' ? 'Sperren' : 'Entsperren'}`,
      isDangerous: true
    });

    if (confirm) {
      let observable;

      if (mode === 'ban') {
        observable = this.contactsService.banUser(this.contact.id);
      } else {
        observable = this.contactsService.unbanUser(this.contact.id);
      }

      observable.subscribe(
        () => {
          // Operation successful
          this.userChange.emit(); // Reload contact/user
        },
        error => {
          // Error
          this.toaster.error(
            `Benutzerkonto des Kontakts konnte nicht ${mode === 'ban' ? 'gesperrt' : 'entsperrt'} werden: `
            + `${error.status} – ${error.statusText}.`
            , 'Fehler'
          );
        }
      );
    }
  }
}
