import { Component, OnInit, ViewChild } from '@angular/core';
import { Router, NavigationEnd } from '@angular/router';
import { filter, tap } from 'rxjs/operators';
import { NgbAccordion } from '@ng-bootstrap/ng-bootstrap';

@Component({
  selector: 'app-menubar',
  templateUrl: './menubar.component.html',
  styleUrls: ['./menubar.component.scss']
})
export class MenubarComponent implements OnInit {
  @ViewChild('menuAccordion', {static: true}) menuAccordion: NgbAccordion;
  public activeMenuItemId: string;

  constructor(
    private router: Router
  ) {}

  ngOnInit() {
    this.activeMenuItemId = this.getMenuItemId(this.router.url);

    this.router.events
      .pipe(filter(event => event instanceof NavigationEnd))
      .subscribe((event: NavigationEnd) => this.menuAccordion.expand(this.getMenuItemId(event.urlAfterRedirects)));
  }

  getMenuItemId(url: string) {
    const menuItem = url.replace('?', '/').split('/')[1];
    return `menu-item-${menuItem}`;
  }
}
