import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { AuthService } from '../../services/auth.service';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.scss']
})
export class LoginComponent implements OnInit {
  public loginForm: FormGroup;
  public errorStatusCode: number;

  constructor(
    private authService: AuthService,
    private router: Router,
    private fb: FormBuilder
  ) { }

  ngOnInit() {
    this.loginForm = this.fb.group({
      email: ['', [Validators.required, Validators.email]],
      password: ['', [Validators.required]]
    });
  }

  login() {
    this.errorStatusCode = null;

    this.authService.login(
      this.loginForm.get('email').value,
      this.loginForm.get('password').value
    ).subscribe(statusCode => {
      if (statusCode === 200) {
        // Get the redirect URL from our auth service
        // If no redirect has been set, use the default
        const redirectUrl =
          this.authService.redirectUrl ? this.authService.redirectUrl : '/';
        // Redirect the user
        this.router.navigate([redirectUrl]);
      } else {
        this.errorStatusCode = statusCode;
        this.loginForm.get('password').reset();
      }
    });
  }
}
