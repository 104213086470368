import { Component, OnInit, TemplateRef, ViewChild, ElementRef } from '@angular/core';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { ContactsService } from '../../services/contacts.service';
import { ToastrService } from 'ngx-toastr';
import { cloneDeep } from 'lodash';
import { Contact } from 'src/app/shared/models/contact.model';
import { SearchService } from '../../services/search.service';
import { Search } from 'src/app/shared/models/search.model';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { CdkDragDrop, moveItemInArray } from '@angular/cdk/drag-drop';
import { saveAs } from 'file-saver';
import { ConfirmDialogService } from 'src/app/shared/services/confirm-dialog.service';

@Component({
  selector: 'app-contacts-search',
  templateUrl: './contacts-search.component.html',
  styleUrls: ['./contacts-search.component.scss']
})
export class ContactsSearchComponent implements OnInit {
  public savedSearches: Search[];
  public selectSearchForm: FormGroup;
  public searchForm: FormGroup;
  public saveSearchForm: FormGroup;
  public manageSearchForms: FormGroup[];
  public searchQuery: any;
  public searchQuerySubmitted = false;
  public missingSearchCriteria = false;
  public contacts: Contact[];
  public totalCount: number;
  public orderBy: any;
  public pageSize = 30;
  public pageNumber: number;
  public columns: any;
  public messages: any;
  public searchTimestamp: Date;

  @ViewChild('table', {static: true}) table: any;
  @ViewChild('expandRowTemplate', {static: true}) expandRowTemplate: TemplateRef<any>;
  @ViewChild('activityStatusTemplate', {static: true}) activityStatusTemplate: TemplateRef<any>;
  @ViewChild('birthDateTemplate', {static: true}) birthDateTemplate: TemplateRef<any>;
  @ViewChild('saveSearchModal', {static: true}) saveSearchModalElement: ElementRef;
  @ViewChild('manageSearchModal', {static: true}) manageSearchModalElement: ElementRef;

  constructor(
    private toaster: ToastrService,
    private contactsService: ContactsService,
    private searchService: SearchService,
    private fb: FormBuilder,
    private modal: NgbModal,
    private confirmDialog: ConfirmDialogService
  ) {}

  ngOnInit() {
    this.selectSearchForm = this.fb.group({
      selectedSavedSearch: [null]
    });

    this.selectSearchForm.get('selectedSavedSearch').valueChanges.subscribe(selectedSavedSearchId => {
      if (selectedSavedSearchId) {
        const query = this.savedSearches.filter(savedSearch => savedSearch.id === selectedSavedSearchId)[0].query;
        this.searchForm.reset(this.transformQueryToSearchForm(query), { emitEvent: false });
      } else {
        this.searchForm.reset({}, { emitEvent: false });
      }
    });

    this.getSearches();

    this.columns = [
      {
        width: '50',
        resizable: false,
        sortable: false,
        draggable: false,
        canAutoResize: false,
        cellTemplate: this.expandRowTemplate
      }, {
        prop: 'lastName',
        name: 'Nachname',
        flexGrow: 1
      }, {
        prop: 'firstName',
        name: 'Vorname',
        flexGrow: 1
      }, {
        name: 'Aktivitätsstatus',
        flexGrow: 0,
        minWidth: 120,
        cellTemplate: this.activityStatusTemplate
      }, {
        prop: 'postCode',
        name: 'PLZ',
        flexGrow: 0,
        minWidth: 60
      }, {
        prop: 'city',
        name: 'Ort',
        flexGrow: 1
      }, {
        prop: 'birthDate',
        name: 'Geb.datum',
        flexGrow: 0,
        minWidth: 90,
        cellTemplate: this.birthDateTemplate
      }, {
        prop: 'email',
        name: 'E-Mail',
        flexGrow: 1
      }, {
        prop: 'phone',
        name: 'Telefon',
        flexGrow: 1
      }
    ];

    this.messages = {
      emptyMessage: 'Keine Daten vorhanden.',
      totalMessage: 'Datensätze insgesamt.'
    };
  }

  private getSearches(selectSearchId?: number) {
    this.searchService.getSearches().subscribe(
      (searches) => {
        this.savedSearches = searches;

        if (selectSearchId) {
          this.selectSearchForm.get('selectedSavedSearch').setValue(selectSearchId, { emitEvent: false });
          this.searchForm.markAsPristine();
        }
      },
    );
  }

  searchFormReady(searchForm: FormGroup) {
    this.searchForm = searchForm;

    this.searchForm.valueChanges.subscribe(status => {
      this.searchQuerySubmitted = false;
      this.selectSearchForm.get('selectedSavedSearch').setValue(null, { emitEvent: false });
    });
  }

  search() {
    this.searchQuery = this.transformSearchFormToQuery(this.searchForm.value);

    if (this.searchQuery) {
      this.searchQuerySubmitted = true;
      this.searchTimestamp = new Date();
      this.setPage({ offset: 0 }); // Trigger search
    }
  }

  export() {
    this.searchService.exportContacts(this.searchQuery).subscribe(data => {
      saveAs(data, `epis_contacts.csv`);
    }, error => this.toaster.error(`Daten konnten nicht exportiert werden: ${error.status} – ${error.statusText}.`, 'Fehler'));
  }

  reset() {
    this.contacts = [];
    this.totalCount = 0;
    this.searchQuery = null;
    this.orderBy = null;
    this.missingSearchCriteria = false;
    this.searchForm.reset();
  }

  private transformQueryToSearchForm(queryRef: any) {
    const formValues = cloneDeep(queryRef);

    if (formValues.male === null) {
      formValues.unknownSex = true;
    } else if (formValues.male) {
      formValues.male = true;
      formValues.female = false;
    } else if (formValues.male === false) {
      formValues.male = false;
      formValues.female = true;
    }

    if (formValues.minPaymentAmount != null) {
      formValues.minPaymentAmount = formValues.minPaymentAmount / 100;
    }

    if (formValues.maxPaymentAmount != null) {
      formValues.maxPaymentAmount = formValues.maxPaymentAmount / 100;
    }

    return formValues;
  }

  private transformSearchFormToQuery(queryRef: any) {
    const query = cloneDeep(queryRef);

    this.deleteIfAllOrNone(query, ['male', 'female', 'unknownSex']);

    if (query.unknownSex) {
      query.male = null;
    } else if (query.female) {
      query.male = false;
    }

    delete query.female;
    delete query.unknownSex;

    if (!query.countries || !query.countries.length) {
      delete query.countries;
    }

    if (query.minAge) {
      query.minAge = parseInt(query.minAge, 10);
    }

    if (query.maxAge) {
      query.maxAge = parseInt(query.maxAge, 10);
    }

    if (!query.hasActivityStatusParticipator) {
      delete query.activityStatusParticipator;
    }

    if (!query.hasActivityStatusDonator) {
      delete query.activityStatusDonator;
    }

    if (!query.hasActivityStatusPatron) {
      delete query.activityStatusPatron;
    }

    delete query.hasActivityStatusParticipator;
    delete query.hasActivityStatusDonator;
    delete query.hasActivityStatusPatron;

    if (!query.interests || !query.interests.length) {
      delete query.interests;
    }

    if (!query.communicationChannels || !query.communicationChannels.length) {
      delete query.communicationChannels;
    }

    if (!query.hasHotlead) {
      delete query.hotlead;
    }

    if (query.minPaymentAmount != null) {
      query.minPaymentAmount = Math.round(query.minPaymentAmount * 100);
    }

    if (query.maxPaymentAmount != null) {
      query.maxPaymentAmount = Math.round(query.maxPaymentAmount * 100);
    }

    delete query.hasHotlead;

    this.deleteEmptyProperties(query, ['male']);

    if (!Object.keys(query).length) {
      // Empty search query
      this.missingSearchCriteria = true;
      return;
    } else {
      this.missingSearchCriteria = false;
    }

    if (this.orderBy) {
      query.orderBy = this.orderBy;
    }

    return query;
  }

  private deleteIfAllOrNone(object: any, propertyNames: string[]) {
    const count = propertyNames.map((a): number => object[a] ? 1 : 0).reduce((a, b) => a + b);

    if (count === 0 || count === propertyNames.length) {
      for (const propertyName of propertyNames) {
        delete object[propertyName];
      }
    }
  }

  private deleteEmptyProperties(object: any, ignoreProperties: string[]) {
    for (const propertyName in object) {
      if (ignoreProperties.findIndex(ip => ip === propertyName) === -1 && object.hasOwnProperty(propertyName)) {
        if (object[propertyName] == null) {
          // Delete null value properties
          delete object[propertyName];
        } else if (
          !!object[propertyName]['trim']
          && object[propertyName].trim() === ''
        ) {
          // Delete empty string properties (whitespaces ignored)
          delete object[propertyName];
        }
      }
    }
  }

  // Datatable functions

  toggleExpandRow(row: any) {
    this.table.rowDetail.toggleExpandRow(row);
  }

  onSort($event: any) {
    this.orderBy = {
      column: $event.sorts[0].prop,
      direction: $event.sorts[0].dir
    };

    this.searchQuery.orderBy = this.orderBy;

    this.setPage({ offset: 0 });
  }

  setPage(pageInfo: any) {
    this.pageNumber = pageInfo.offset;

    this.searchService.searchContacts(this.searchQuery, this.pageNumber * this.pageSize, this.pageSize).subscribe(
      (result: any) => {
        this.contacts = result.contacts;
        this.totalCount = result.count;
      },
      error => this.toaster.error(`Daten konnten nicht geladen werden: ${error.status} – ${error.statusText}.`, 'Fehler')
    );
  }

  // Modal dialogs

  openSaveSearchModal() {
    this.saveSearchForm = this.fb.group({
      selectedSavedSearch: [null],
      name: [null, [Validators.required]]
    });

    const nameControl = this.saveSearchForm.get('name');

    this.saveSearchForm.get('selectedSavedSearch').valueChanges.subscribe(value => {
      if (value !== null) {
        nameControl.clearValidators();
      } else {
        nameControl.setValidators(Validators.required);
      }

      nameControl.updateValueAndValidity();
    });

    this.modal.open(this.saveSearchModalElement, { backdrop: 'static' }).result.then(result => {
      // Close
    }, reason => {
      // Dismiss
    });
  }

  saveSearch(closeModal: Function) {
    const saveSearchFormData = this.saveSearchForm.value;
    let search: any;
    let observable$: any;

    if (saveSearchFormData.selectedSavedSearch === null) {
      // Create
      search = {
        name: saveSearchFormData.name,
        query: this.searchQuery
      };

      observable$ = this.searchService.createSearch(search);
    } else {
      // Update
      search = {
        name: saveSearchFormData.name || undefined,
        query: this.searchQuery
      };

      observable$ = this.searchService.updateSearch(saveSearchFormData.selectedSavedSearch, search);
    }

    observable$.subscribe(
      result => {
        // Reload saved searches for selection
        if (saveSearchFormData.selectedSavedSearch === null) {
          // Created: select new search
          this.getSearches(result.id);
        } else {
          // Updated
          this.getSearches(saveSearchFormData.selectedSavedSearch);
        }

        closeModal();
        this.toaster.success('Suche wurde als Vorlage wurde gespeichert.');
      }, error => {
        this.toaster.error(`Suche konnte nicht gespeichert werden: ${error.status} – ${error.statusText}.`, 'Fehler');
      }
    );
  }

  openManageSearchModal() {
    this.manageSearchForms = [];

    for (const savedSearch of this.savedSearches) {
      this.manageSearchForms.push(this.fb.group({
        name: [savedSearch.name, [Validators.required]]
      }));
    }

    this.modal.open(this.manageSearchModalElement, { backdrop: 'static' }).result.then(result => {
      // Close
    }, reason => {
      // Dismiss
    });
  }

  updateSearchName(i: number) {
    const updatedName = this.manageSearchForms[i].get('name').value;

    this.searchService.updateSearch(this.savedSearches[i].id, {
      name: updatedName
    }).subscribe(() => {
      this.manageSearchForms[i].markAsPristine();
      this.savedSearches[i].name = updatedName;
      this.toaster.success('Name der Suchvorlage wurde aktualisiert.');
    }, error => {
      this.toaster.error(`Name der Suchvorlage konnte nicht aktualisiert werden: ${error.status} – ${error.statusText}.`, 'Fehler');
    });
  }

  async deleteSearch(i: number) {
    const confirm = await this.confirmDialog.confirm({
      message: 'Wollen Sie diese Suchvorlage sicher löschen?',
      confirmText: 'Löschen',
      isDangerous: true
    });

    if (confirm) {
      const id = this.savedSearches[i].id;

      this.searchService.deleteSearch(id).subscribe(() => {
        this.manageSearchForms.splice(i, 1);
        this.savedSearches.splice(i, 1);

        if (this.selectSearchForm.get('selectedSavedSearch').value === id) {
          // Reset search if deleted one was currently selected
          this.reset();
        }

        this.toaster.success('Suchvorlage wurde gelöscht.');
      },
      error => {
        // Error
        if (error.status === 400 && error.error.message === 'SearchInUse') {
          this.toaster.warning(
            'Suchvorlage konnte nicht gelöscht werden, da diese noch in Verwendung ist.'
            + ' Bitte entfernen Sie zuerst Verteilerlisten (Kommunikation), die diese'
            + ' Suchvorlage verwenden, und versuchen Sie dann erneut zu löschen.',
            'Warnung', { disableTimeOut: true }
          );
        } else {
          this.toaster.error(`Suchvorlage konnte nicht gelöscht werden: ${error.status} – ${error.statusText}.`, 'Fehler');
        }
      });
    }
  }

  updateSearchSortKeys($event: CdkDragDrop<FormGroup[]>) {
    moveItemInArray(this.manageSearchForms, $event.previousIndex, $event.currentIndex);

    const savedSearchesCopy = cloneDeep(this.savedSearches);
    moveItemInArray(savedSearchesCopy, $event.previousIndex, $event.currentIndex);
    savedSearchesCopy.map((savedSearch, i) => savedSearch.sortKey = i); // New sort keys

    this.searchService.bulkUpdateSearches(this.savedSearches.map(savedSearch => {
      return {
        id: savedSearch.id,
        sortKey: savedSearch.sortKey
      };
    })).subscribe(() => {
      this.savedSearches = cloneDeep(savedSearchesCopy);
    }, error => {
      // Sort back if update fails
      moveItemInArray(this.manageSearchForms, $event.currentIndex, $event.previousIndex);
      this.toaster.error(`Umreihung fehlgeschlagen: ${error.status} – ${error.statusText}.`, 'Fehler');
    });
  }
}
