import { User } from './user.model';
import { ContactCountry } from './contact-country.model';
import { ContactInterest } from './contact-interest.model';

export class Contact {
  id?: number;
  male: boolean;
  title: string;
  firstName: string;
  lastName: string;
  email: string;
  street: string;
  postCode: string;
  city: string;
  country: ContactCountry | string; // Country object or just key
  phone: string;
  birthDate: string;
  profession: string;
  company: string;
  companyPosition: string;
  organization: string;
  organizationPosition: string;
  activityStatusDonator: boolean;
  activityStatusParticipator: boolean;
  activityStatusPatron: boolean;
  interests: ContactInterest[] | number[]; // Interest objects or just array of ids
  hotleadDeadline: Date;
  notes?: string;
  user?: User;
  // Metadata
  readonly createdAt: Date;
  readonly createdFromIP: string;
  readonly createdBy: User;
  readonly updatedAt: Date;
  readonly updatedFromIP: string;
  readonly updatedBy: User;
}
