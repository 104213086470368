import { Component, OnInit } from '@angular/core';
import { AuthService } from '../../services/auth.service';
import { Router } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import { FollowupsService } from 'src/app/contacts/services/followups.service';
import { interval } from 'rxjs';
import { startWith, map } from 'rxjs/operators';
import { Followup } from 'src/app/shared/models/followup.model';
import { Contact } from 'src/app/shared/models/contact.model';

@Component({
  selector: 'app-header-functions',
  templateUrl: './header-functions.component.html',
  styleUrls: ['./header-functions.component.scss']
})
export class HeaderFunctionsComponent implements OnInit {
  private readonly pollInSecs = 180; // Polling interval for notification in seconds
  public notifications = [];

  constructor(
    public authService: AuthService,
    private toaster: ToastrService,
    private followupsService: FollowupsService
  ) {}

  ngOnInit() {
    interval(this.pollInSecs * 1000).pipe(startWith(0)).subscribe(() => this.getNotifications());
    this.followupsService.followupsChanged.subscribe(() => this.getNotifications());
  }

  getNotifications() {
    this.followupsService.getMyFollowups({
      expiredOnly: true,
      assignedToMeOnly: true,
      disableLoadingSpinner: true
    })
    .pipe(map(followups => this.convertFollowupsToNotifications(followups)))
    .subscribe(notifications => this.notifications = notifications);
  }

  convertFollowupsToNotifications(followups: Followup[]) {
    return followups.map(followup => {
      const followupContact = followup.contact as Contact;
      const notification: any = {};

      notification.type = 'WV';

      if (followupContact.firstName && followupContact.lastName) {
        notification.text = followupContact.firstName + ' ' + followupContact.lastName;
      } else {
        notification.text = followupContact.email;
      }

      notification.timestamp = followup.deadline;
      notification.link = '/contacts/detail/' + followupContact.id;

      return notification;
    });
  }

  openChangePasswordModal() {
    this.authService.openChangePasswordModal();
  }

  deimpersonate() {
    this.authService.deimpersonate().subscribe(() => {
      window.location.replace('/system/users');
    }, error => this.toaster.error(`Abmeldung fehlgeschlagen: ${error.status} – ${error.statusText}.`, 'Fehler'));   
  }

  logout() {
    this.authService.logout().subscribe(() => {
      window.location.replace('/login');
    }, error => this.toaster.error(`Abmeldung fehlgeschlagen: ${error.status} – ${error.statusText}.`, 'Fehler'));
  }
}
