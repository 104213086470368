import { Injectable } from '@angular/core';
import { NgbDateStruct, NgbDateAdapter } from '@ng-bootstrap/ng-bootstrap';
import { isoDateStringToNgbDate, ngbDateToIsoDateString } from './date.functions';

@Injectable()
export class NgbDateISOStringAdapter extends NgbDateAdapter<string> {
  fromModel(isoDateString: string): NgbDateStruct {
    return isoDateStringToNgbDate(isoDateString);
  }

  toModel(ngbDate: NgbDateStruct): string {
    return ngbDateToIsoDateString(ngbDate);
  }
}
