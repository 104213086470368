import { Injectable } from '@angular/core';
import { ContactsService } from './contacts.service';
import { Contact } from 'src/app/shared/models/contact.model';
import { cloneDeep } from 'lodash';
import { Observable, of } from 'rxjs';
import { SearchService } from './search.service';
import { mergeMap } from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})
export class ContactsQuicksearchService {
  public searchResults: Contact[];
  private cachedSearchText: string;
  private cachedSearchId: number;
  private endReached: boolean;
  readonly take = 25;

  constructor(
    private contactsService: ContactsService,
    private searchService: SearchService
  ) {
    this.clearResults();
  }

  public searchByText(text?: string): Observable<any> {
    if (this.endReached) {
      return of(null);
    }

    text = text || this.cachedSearchText;

    return this.contactsService.getContacts(text, this.searchResults.length, this.take).pipe(
      mergeMap(contacts => {
        this.cachedSearchText = text;

        if (contacts.length === 0 || contacts.length < this.take) {
          this.endReached = true;
        }

        this.searchResults = this.searchResults.concat(contacts);

        return of(null);
      })
    );
  }

  public performSavedSearch(id?: number): Observable<any> {
    if (this.endReached) {
      return of(null);
    }

    id = id || this.cachedSearchId;

    return this.searchService.performSavedSearch(id, this.searchResults.length, this.take).pipe(
      mergeMap(result => {
        this.cachedSearchId = id;

        const contacts = result.contacts;

        if (contacts.length === 0 || contacts.length < this.take) {
          this.endReached = true;
        }

        this.searchResults = this.searchResults.concat(contacts);

        return of(null);
      })
    );
  }

  public updateInResults(contact: Contact) {
    const index = this.searchResults.findIndex(searchResult => searchResult.id === contact.id);
    if (index !== -1) {
      this.searchResults[index] = cloneDeep(contact);
    }
  }

  public clearResults() {
    this.searchResults = [];
    this.cachedSearchText = null;
    this.endReached = false;
  }
}
