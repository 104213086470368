import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-payment-transactions-master',
  templateUrl: './payment-transactions-master.component.html',
  styleUrls: ['./payment-transactions-master.component.scss']
})
export class PaymentTransactionsMasterComponent implements OnInit {

  constructor() { }

  ngOnInit() {
  }

}
