import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { HeaderComponent } from './components/header/header.component';
import { MenubarComponent } from './components/menubar/menubar.component';
import { RouterModule } from '@angular/router';
import { LoginComponent } from './components/login/login.component';
import { AppLayoutComponent } from './components/app-layout/app-layout.component';
import { SharedModule } from '../shared/shared.module';
import { HeaderBrandComponent } from './components/header-brand/header-brand.component';
import { HeaderFunctionsComponent } from './components/header-functions/header-functions.component';
import { ChangePasswordComponent } from './components/change-password/change-password.component';

@NgModule({
  imports: [
    CommonModule,
    RouterModule,
    SharedModule
  ],
  exports: [
    HeaderComponent,
    MenubarComponent
  ],
  declarations: [
    HeaderComponent,
    MenubarComponent,
    LoginComponent,
    AppLayoutComponent,
    HeaderBrandComponent,
    HeaderFunctionsComponent,
    ChangePasswordComponent
  ],
  entryComponents: [
    ChangePasswordComponent
  ]
})
export class CoreModule { }
