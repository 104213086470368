import { ValidatorFn, FormGroup, ValidationErrors } from '@angular/forms';

export function passwordMatchValidator(controlName1: string, controlName2: string): ValidatorFn {
  return (form: FormGroup): ValidationErrors | null => {
    const password1 = form.get(controlName1);
    const password2 = form.get(controlName2);

    if (password2.dirty) {
      return password1 && password2 && password1.value === password2.value
        ? null : { 'passwordMismatch': true };
    } else {
      return null;
    }
  };
}
