import { Component, OnInit, Input } from '@angular/core';
import { Contact } from 'src/app/shared/models/contact.model';

@Component({
  selector: 'app-contacts-quicksearch-result-item',
  templateUrl: './contacts-quicksearch-result-item.component.html',
  styleUrls: ['./contacts-quicksearch-result-item.component.scss']
})
export class ContactsQuicksearchResultItemComponent implements OnInit {
  @Input()
  public contact: Contact;

  constructor() {}

  ngOnInit() {
  }
}
