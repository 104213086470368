import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { environment } from '../../../environments/environment';
import { Observable } from 'rxjs';
import { CommunicationList } from 'src/app/shared/models/communication-list.model';

@Injectable({
  providedIn: 'root'
})
export class CommunicationListsService {
  constructor(
    private httpClient: HttpClient
  ) { }

  getLists(): Observable<any[]> {
    return this.httpClient.get<any[]>(`${environment.api.baseUrl}communication/lists`);
  }

  saveList(partialList: Partial<CommunicationList>): Observable<CommunicationList> {
    if (partialList.id) {
      // Update
      return this.httpClient.put<CommunicationList>(`${environment.api.baseUrl}communication/lists/${partialList.id}`, partialList);
    } else {
      // Create
      return this.httpClient.post<CommunicationList>(`${environment.api.baseUrl}communication/lists`, partialList);
    }
  }

  deleteList(id: number): Observable<any> {
    return this.httpClient.delete<CommunicationList>(`${environment.api.baseUrl}communication/lists/${id}`);
  }
}
