import { NgModule } from '@angular/core';
import { SharedModule } from '../shared/shared.module';

import { ContactsQuicksearchResultItemComponent } from './components/contacts-quicksearch-result-item/contacts-quicksearch-result-item.component'; // tslint:disable
import { ContactMainComponent } from './components/contact-main/contact-main.component';
import { ContactPaymentsComponent } from './components/contact-payments/contact-payments.component';
import { ContactCommunicationComponent } from './components/contact-communication/contact-communication.component';
import { ContactSystemComponent } from './components/contact-system/contact-system.component';
import { ContactDetailComponent } from './components/contact-detail/contact-detail.component';
import { ContactsSearchComponent } from './components/contacts-search/contacts-search.component';
import { ContactsSearchFormComponent } from './components/contacts-search-form/contacts-search-form.component';
import { ContactCreateComponent } from './components/contact-create/contact-create.component';
import { ContactDetailMasterComponent } from './components/contact-detail-master/contact-detail-master.component';
import { CommunicationModule } from '../communication/communication.module';
import { PaymentModule } from '../payment/payment.module';

@NgModule({
  imports: [
    SharedModule,
    CommunicationModule,
    PaymentModule
  ],
  declarations: [
    ContactDetailComponent,
    ContactsQuicksearchResultItemComponent,
    ContactMainComponent,
    ContactPaymentsComponent,
    ContactCommunicationComponent,
    ContactSystemComponent,
    ContactsSearchComponent,
    ContactsSearchFormComponent,
    ContactCreateComponent,
    ContactDetailMasterComponent
  ]
})
export class ContactsModule { }
