import { Component, OnInit, ViewChild, ElementRef } from '@angular/core';
import { UsersService } from '../../services/users.service';
import { User, UserState } from '../../../shared/models/user.model';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { Role } from '../../../shared/models/role.model';
import { FormGroup, FormBuilder, Validators, FormControl } from '@angular/forms';
import { cloneDeep } from 'lodash';
import { ToastrService } from 'ngx-toastr';
import { AuthService } from '../../../core/services/auth.service';
import { passwordMatchValidator } from '../../../shared/misc/password-match.validator';

@Component({
  selector: 'app-users',
  templateUrl: './users.component.html',
  styleUrls: ['./users.component.scss']
})
export class UsersComponent implements OnInit {
  @ViewChild('addEditUserModal', {static: true})
  addEditUserModalElement: ElementRef;

  @ViewChild('changePasswordModal', {static: true})
  changePasswordModalElement: ElementRef;

  public users: User[];
  public myUserId: number;
  public isImpersonated: boolean;
  public availableRoles: Role[];
  public UserState: typeof UserState = UserState;
  public addEditUserForm: FormGroup;
  public editMode: boolean;
  public changePasswordForm: FormGroup;
  public generalError: string;

  constructor(
    private usersService: UsersService,
    private authService: AuthService,
    private modal: NgbModal,
    private fb: FormBuilder,
    private toaster: ToastrService
  ) { }

  ngOnInit() {
    this.usersService.getUsers(true).subscribe(
      users => this.users = users,
      error => this.toaster.error(`Daten konnten nicht geladen werden: ${error.status} – ${error.statusText}.`, 'Fehler')
    );

    this.myUserId = this.authService.user.id;
    this.isImpersonated = !!this.authService.user.realUser;
    this.availableRoles = this.authService.roles;
  }

  openAddEditUserModal(user?: User) {
    this.addEditUserForm = this.fb.group({
      id: [null],
      email: [null, [Validators.email, Validators.required]],
      firstName: [null, [Validators.required]],
      lastName: [null, [Validators.required]],
      male: [true, [Validators.required]],
      role: [null, [Validators.required]],
      state: [UserState.VERIFIED, [Validators.required]]
    });

    // Edit
    if (user) {
      this.editMode = true;

      if (this.authService.user.id === user.id) {
        this.addEditUserForm.get('role').disable();
        this.addEditUserForm.get('state').disable();
      }

      this.addEditUserForm.patchValue(user);
    } else {
      // Add
      this.editMode = false;

      this.addEditUserForm.addControl('password1', new FormControl(null, [Validators.required, Validators.minLength(8)]));
      this.addEditUserForm.addControl('password2', new FormControl(null, [Validators.required, Validators.minLength(8)]));
      this.addEditUserForm.setValidators(passwordMatchValidator('password1', 'password2'));

      const employeeRole = this.availableRoles.find(role => role.key === 'employee');
      this.addEditUserForm.get('role').setValue(employeeRole);
    }

    this.modal.open(this.addEditUserModalElement, { backdrop: 'static' }).result.then(result => {
      // Close
    }, reason => {
      // Dismiss
    });
  }

  saveUser(closeModal: Function) {
    const newUser = cloneDeep(this.addEditUserForm.value);
    console.log(newUser);

    if (newUser.id) {
      // Update
      if (this.authService.user.id === newUser.id) {
        delete newUser.role;
        delete newUser.status;
      }

      if (newUser.role) {
        newUser.roleKey = newUser.role.key;
        delete newUser.role;
      }

      this.usersService.saveUser(newUser).subscribe(updatedUser => {
        this.users[this.users.findIndex(u => u.id === updatedUser.id)] = updatedUser;
        this.users.sort(this.compareForUserSort);
        this.toaster.success('Systembenutzer wurde aktualisiert.');
        closeModal();
      }, error => {
        if (error.status === 409) {
          this.addEditUserForm.get('email').setErrors({ conflict: true });
        } else {
          this.toaster.error(`Systembenutzer konnte nicht aktualisiert werden: ${error.status} – ${error.statusText}.`, 'Fehler');
        }
      });
    } else {
      // Create
      newUser.roleKey = newUser.role.key;
      delete newUser.role;

      newUser.password = newUser.password1;
      delete newUser.password1;
      delete newUser.password2;

      this.usersService.saveUser(newUser).subscribe(createdUser => {
        this.users.push(createdUser);
        this.users.sort(this.compareForUserSort);
        this.toaster.success('Neuer Systembenutzer wurde hinzugefügt.');
        closeModal();
      }, error => {
        if (error.status === 409) {
          this.addEditUserForm.get('email').setErrors({ conflict: true });
        } else {
          this.toaster.error(`Systembenutzer konnte nicht hinzugefügt werden: ${error.status} – ${error.statusText}.`, 'Fehler');
        }
      });
    }
  }

  openChangePasswordModal(user: User) {
    this.authService.openChangePasswordModal(user.id, user.email);
  }

  changePassword(closeModal: Function) {
    const userId = this.changePasswordForm.get('id').value;
    const newPassword = this.changePasswordForm.get('password1').value;

    this.usersService.saveUser({
      id: userId,
      password: newPassword
    }).subscribe(changedUser => {
      this.toaster.success('Passwort wurde geändert.');
      closeModal();
    }, error => this.toaster.error(`Passwort konnte nicht geändert werden: ${error.status} – ${error.statusText}.`, 'Fehler'));
  }

  impersonate(asUser: User) {
    this.authService.impersonate(asUser.id).subscribe(() => {
      window.location.replace('/');
    }, error => this.toaster.error(`Anmeldung fehlgeschlagen: ${error.status} – ${error.statusText}.`, 'Fehler'));
  }

  compareRoles(r1: Role, r2: Role): boolean {
    return r1 && r2 && r1.key === r2.key;
  }

  compareForUserSort(u1: User, u2: User): number {
    const l1 = u1.lastName.toLocaleLowerCase();
    const l2 = u2.lastName.toLocaleLowerCase();
    const f1 = u1.firstName.toLocaleLowerCase();
    const f2 = u2.firstName.toLocaleLowerCase();

    if (l1 < l2) { return -1; }
    if (l1 > l2) { return 1; }
    if (l1 === l2) {
      if (f1 < f2) { return -1; }
      if (f1 > f2) { return 1; }
      if (f1 === f2) { return u1.id < u2.id ? -1 : 1; }
    }
  }
}
