import { Component, OnInit, Input, OnChanges, ViewChild, ElementRef, Output, EventEmitter } from '@angular/core';
import { PaymentStripeService } from '../../services/payment-stripe.service';
import { ToastrService } from 'ngx-toastr';
import { environment } from 'src/environments/environment';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { ConfirmDialogService } from 'src/app/shared/services/confirm-dialog.service';
import { Validators, FormGroup, FormBuilder } from '@angular/forms';
import { addDays } from 'date-fns';

@Component({
  selector: 'app-payment-contact-configuration',
  templateUrl: './payment-contact-configuration.component.html',
  styleUrls: ['./payment-contact-configuration.component.scss']
})
export class PaymentContactConfigurationComponent implements OnInit, OnChanges {
  @Input()
  public contactId: number;

  @Input()
  public contactName: string;

  @Input()
  public contactEmail: string;

  @Output()
  public membershipChange: EventEmitter<any> = new EventEmitter();

  @ViewChild('showDetailsModal', { static: true })
  showDetailsModalElement: ElementRef;

  @ViewChild('addPaymentSourceModal', { static: true })
  addPaymentSourceModalElement: ElementRef;

  @ViewChild('addEditSubscriptionModal', { static: true })
  addEditSubscriptionModalElement: ElementRef;

  public stripeDashboardUrl: string;
  public details: any;

  public stripeCustomerId: string;
  public stripePaymentMethods: any;
  public stripePaymentSources: any;
  public cardBrandsReadable: any;

  public stripeSubscriptions: any;

  public addPaymentSourceForm: FormGroup;
  public addEditSubscriptionForm: FormGroup;
  public editMode: boolean;

  public addDays = addDays; // Provide in view

  readonly availablePaymentTypes = [
    { key: 'donation', name: 'Dauerspende' },
    { key: 'membership', name: 'Fördermitgliedschaft' }
  ];

  readonly availablePaymentIntervals = [
    { key: 'monthly', name: 'Monatlich' },
    { key: 'quarterly', name: 'Quartalsweise' },
    { key: 'halfyearly', name: 'Halbjährlich' },
    { key: 'yearly', name: 'Jährlich' },
  ];

  constructor(
    private paymentStripeService: PaymentStripeService,
    private toaster: ToastrService,
    private modal: NgbModal,
    private confirmDialog: ConfirmDialogService,
    private fb: FormBuilder
  ) {}

  ngOnInit() {
    this.stripeDashboardUrl = environment.stripeDashboardUrl;
    this.cardBrandsReadable = this.paymentStripeService.cardBrandsReadable;
  }

  ngOnChanges() {
    this.paymentStripeService.getPaymentMethodsSources(this.contactId).subscribe(result => {
      ({
        customerId: this.stripeCustomerId,
        paymentMethods: this.stripePaymentMethods,
        sources: this.stripePaymentSources
      } = result);

      this.paymentStripeService.getSubscriptions(this.contactId).subscribe(
        subscriptions => {
          this.stripeSubscriptions = subscriptions;
          this.updatePaymentMethodSourcesUsage();
        },
        error2 => this.toaster.error(`Dauerzahlungen konnten nicht geladen werden: ${error2.status} – ${error2.statusText}.`, 'Fehler')
      );
    },
      error => this.toaster.error(`Zahlungsmittel konnten nicht geladen werden: ${error.status} – ${error.statusText}.`, 'Fehler')
    );
  }

  updatePaymentMethodSourcesUsage() {
    this.stripePaymentMethods.map(pm => {
      pm.inUse = this.stripeSubscriptions.findIndex(s => s.default_payment_method && s.default_payment_method.id === pm.id) !== -1;
      return pm;
    });

    this.stripePaymentSources.map(ps => {
      ps.inUse = this.stripeSubscriptions.findIndex(s => s.default_source && s.default_source.id === ps.id) !== -1;
      return ps;
    });
  }

  openShowPaymentMethodDetailsModal(index: number) {
    this.openShowDetailsModal(this.stripePaymentMethods[index]);
  }

  openShowPaymentSourceDetailsModal(index: number) {
    this.openShowDetailsModal(this.stripePaymentSources[index]);
  }

  openShowDetailsModal(details) {
    this.details = details;
    this.modal.open(this.showDetailsModalElement, {
      backdrop: 'static',
      scrollable: true
    }).result.then(result => {
      // Close
    }, reason => {
      // Dismiss
    });
  }

  async deletePaymentMethod(index: number) {
    const confirm = await this.confirmDialog.confirm({
      message: this.stripePaymentMethods[index].default ?
        'Wollen Sie das Standard-Zahlungsmittel, das für wiederkehrende Zahlungen ohne dezidiert zugeordnetes Zahlungsmittel verwendet wird, sicher löschen?'
        :
        'Wollen Sie dieses Zahlungsmittel sicher löschen?',
      confirmText: 'Löschen',
      isDangerous: true
    });

    if (confirm) {
      this.paymentStripeService.deletePaymentMethod(this.contactId, this.stripePaymentMethods[index].id).subscribe(result => {
        this.toaster.success('Zahlungsmittel wurde gelöscht.');
        this.stripePaymentMethods.splice(index, 1);
      },
        error => this.toaster.error(`Zahlungsmittel konnte nicht gelöscht werden: ${error.status} – ${error.statusText}.`, 'Fehler')
      );
    }
  }

  async deletePaymentSource(index: number) {
    const confirm = await this.confirmDialog.confirm({
      message: this.stripePaymentSources[index].default ?
        'Wollen Sie das Standard-Zahlungsmittel, das für wiederkehrende Zahlungen ohne dezidiert zugeordnetes Zahlungsmittel verwendet wird, sicher löschen?'
        :
        'Wollen Sie dieses Zahlungsmittel sicher löschen?',
      confirmText: 'Löschen',
      isDangerous: true
    });

    if (confirm) {
      this.paymentStripeService.deletePaymentSource(this.contactId, this.stripePaymentSources[index].id).subscribe(result => {
        this.toaster.success('Zahlungsmittel wurde gelöscht.');
        this.stripePaymentSources.splice(index, 1);
      },
        error => this.toaster.error(`Zahlungsmittel konnte nicht gelöscht werden: ${error.status} – ${error.statusText}.`, 'Fehler')
      );
    }
  }

  openAddPaymentSourceModal() {
    this.addPaymentSourceForm = this.fb.group({
      iban: [undefined, [Validators.required, Validators.pattern('[A-Za-z]{2}[\\d\\s]{8,}')]],
      ownerName: [this.contactName, [Validators.required]],
      ownerEmail: [this.contactEmail, [Validators.required, Validators.email]]
    });

    this.modal.open(this.addPaymentSourceModalElement, { backdrop: 'static' }).result.then(paymentSource => {
      // Close
    }, reason => {
      // Dismiss
    });
  }

  createPaymentSource(closeModal: Function) {
    const paymentSource = this.addPaymentSourceForm.value;

    this.paymentStripeService.createPaymentSource(this.contactId, paymentSource).subscribe(newPaymentSource => {
      closeModal();
      this.stripePaymentSources.push(newPaymentSource);
      this.toaster.success('SEPA-Mandat wurde hinzugefügt.');
    },
      // tslint:disable-next-line:max-line-length
      error => this.toaster.error(`SEPA-Mandat konnte nicht hinzugefügt werden: ${error.status} – ${error.error ? error.error.message || error.statusText : error.statusText}.`, 'Fehler')
    );
  }

  openShowSubscriptionDetailsModal(details) {
    this.details = details;
    this.modal.open(this.showDetailsModalElement, {
      backdrop: 'static',
      scrollable: true
    }).result.then(result => {
      // Close
    }, reason => {
      // Dismiss
    });
  }

  openAddEditSubscriptionModal(subscription?: any) {
    this.addEditSubscriptionForm = this.fb.group({
      id: [subscription ? subscription.id : undefined],
      type: ['donation', [Validators.required]],
      interval: ['monthly', [Validators.required]],
      paymentMethodSource: [undefined, [Validators.required]],
      amount: [1, [Validators.required, Validators.min(1)]]
    });

    if (subscription) {
      // Edit
      this.editMode = true;

      this.addEditSubscriptionForm.get('type').disable();

      this.addEditSubscriptionForm.patchValue({
        id: subscription.id,
        type: subscription.plan.id.split('_')[0],
        interval: subscription.plan.id.split('_')[1],
        paymentMethodSource:
          subscription.default_source && subscription.default_source.id
          || subscription.default_payment_method && subscription.default_payment_method.id,
        amount: subscription.quantity
      });
    } else {
      // Add
      this.editMode = false;
      const hasMembershipSubscriptions = this.stripeSubscriptions.findIndex(s => s.plan.id.split('_')[0] === 'membership') !== -1;

      if (hasMembershipSubscriptions) {
        // Only one "membership" subscription allowed
        this.addEditSubscriptionForm.get('type').disable();
      }
    }

    this.modal.open(this.addEditSubscriptionModalElement, { backdrop: 'static' }).result.then(result => {
      // Close
    }, reason => {
      // Dismiss
    });
  }

  saveSubscription(closeModal: Function) {
    const subscriptionFormValue = this.addEditSubscriptionForm.getRawValue();
    let subscription: any = {};

    const paymentMethodSource = this.stripePaymentMethods
      .concat(this.stripePaymentSources)
      .find(o => o.id === subscriptionFormValue.paymentMethodSource);

    if (paymentMethodSource.object === 'source') {
      subscription.sourceId = subscriptionFormValue.paymentMethodSource;
    } else if (paymentMethodSource.object === 'payment_method') {
      subscription.paymentMethod = subscriptionFormValue.paymentMethodSource;
    }

    console.log(subscription);

    if (!subscriptionFormValue.id) {
      // Create
      subscription = {
        ...subscription,
        plan: subscriptionFormValue.type + '_' + subscriptionFormValue.interval,
        amount: subscriptionFormValue.amount * 100,
      };

      this.paymentStripeService.createSubscription(this.contactId, subscription).subscribe(newSubscription => {
        this.toaster.success('Dauerzahlung wurde hinzugefügt.');
        this.stripeSubscriptions.push(newSubscription);
        this.updatePaymentMethodSourcesUsage();

        if (subscriptionFormValue.type === 'membership') {
          this.membershipChange.emit(true);
        }

        closeModal();
      }, // tslint:disable-next-line:max-line-length
        error => this.toaster.error(`Dauerzahlung konnte nicht hinzugefügt werden: ${error.status} – ${error.error ? error.error.message || error.statusText : error.statusText}.`, 'Fehler')
      );
    } else {
      // Update
      subscription = {
        ...subscription,
        plan: subscriptionFormValue.type + '_' + subscriptionFormValue.interval,
        amount: subscriptionFormValue.amount * 100
      };

      this.paymentStripeService.updateSubscription(
        this.contactId, subscriptionFormValue.id, subscription
      ).subscribe(updatedSubscription => {
        this.toaster.success('Dauerzahlung wurde aktualisiert.');
        const index = this.stripeSubscriptions.findIndex(s => s.id === subscriptionFormValue.id);
        this.stripeSubscriptions[index] = updatedSubscription;
        this.updatePaymentMethodSourcesUsage();
        closeModal();
      }, // tslint:disable-next-line:max-line-length
        error => this.toaster.error(`Dauerzahlung konnte nicht aktualisiert werden: ${error.status} – ${error.error ? error.error.message || error.statusText : error.statusText}.`, 'Fehler')
      );
    }
  }

  async deleteSubscription(subscriptionId: string, closeModal: Function) {
    const index = this.stripeSubscriptions.findIndex(s => s.id === subscriptionId);

    const confirm = await this.confirmDialog.confirm({
      message: 'Wollen Sie diese Dauerzahlung sicher löschen?',
      confirmText: 'Löschen',
      isDangerous: true
    });

    if (confirm) {
      this.paymentStripeService.deleteSubscription(this.contactId, subscriptionId).subscribe(result => {
        this.toaster.success('Dauerzahlung wurde gelöscht.');
        this.stripeSubscriptions.splice(index, 1);
        this.updatePaymentMethodSourcesUsage();

        if (result.plan.id.split('_')[0] === 'membership') {
          this.membershipChange.emit(false);
        }

        closeModal();
      },
        error => this.toaster.error(`Dauerzahlung konnte nicht gelöscht werden: ${error.status} – ${error.statusText}.`, 'Fehler')
      );
    }
  }
}
