import { Injectable } from '@angular/core';
import { CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot, Router } from '@angular/router';
import { Observable, of } from 'rxjs';
import { AuthService } from '../services/auth.service';
import { tap, map } from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})
export class AuthGuard implements CanActivate {
  constructor(
    private authService: AuthService,
    private router: Router
  ) { }

  canActivate(
    next: ActivatedRouteSnapshot,
    state: RouterStateSnapshot): Observable<boolean> | Promise<boolean> | boolean {
      return this.checkLogin(state.url).pipe(
        map(isAuthenticated => {
          if (isAuthenticated) {
            if (next.data.minRole) {
              // Role restrictions
              return this.authService.isMinRole(next.data.minRole);
            } else {
              // Authenticated and no role restrictions
              return true;
            }
          } else {
            // Not authenticated
            return false;
          }
        })
      );
  }

  checkLogin(url: string): Observable<boolean> {
    if (this.authService.user) { return of(true); }

    return this.authService.getSession().pipe(
      tap(isAuthenticated => {
        if (!isAuthenticated) {
          this.authService.redirectUrl = url;
          this.router.navigate(['/login']);
        }
      })
    );
  }
}
