import { NgbDateStruct } from '@ng-bootstrap/ng-bootstrap';

export function ngbDateToIsoDateString(ngbDate: NgbDateStruct): string {
  if (ngbDate && Number.isInteger(ngbDate.year) && Number.isInteger(ngbDate.month) && Number.isInteger(ngbDate.day)) {
    return `${ngbDate.year}-${padMonthOrDay(ngbDate.month)}-${padMonthOrDay(ngbDate.day)}`;
  }

  return null;
}

export function isoDateStringToNgbDate(isoDateString: string): NgbDateStruct {
  if (isoDateString) {
    let year: number, month: number, day: number;

    isoDateString = isoDateString.split('T')[0]; // strip (optional) time
    [year, month, day] = isoDateString.split('-').map(x => +x);

    if (Number.isInteger(year) && Number.isInteger(month) && Number.isInteger(day)) {
      return { year, month, day };
    }
  }

  return null;
}

function padMonthOrDay(monthOrDay: number): string {
  return monthOrDay < 10 ? '0' + monthOrDay.toString() : monthOrDay.toString();
}
