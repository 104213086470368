import { Component, OnInit, HostBinding, Host, ViewChild, ElementRef } from '@angular/core';
import { FormGroup, Validators, FormBuilder } from '@angular/forms';
import { ContactsService } from '../../services/contacts.service';
import { ActivatedRoute } from '@angular/router';
import { Contact } from 'src/app/shared/models/contact.model';
import { ToastrService } from 'ngx-toastr';
import { ContactCountry } from 'src/app/shared/models/contact-country.model';
import { ContactInterest } from 'src/app/shared/models/contact-interest.model';
import { ContactsQuicksearchService } from '../../services/contacts-quicksearch.service';
import { NgbModal, NgbTabset } from '@ng-bootstrap/ng-bootstrap';
import { isoDateStringToNgbDate } from 'src/app/shared/misc/date.functions';
import { format, addYears, addDays, isFuture, setHours, setMinutes } from 'date-fns';
import { UsersService } from 'src/app/system/services/users.service';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { FollowupsService } from '../../services/followups.service';
import { Followup } from 'src/app/shared/models/followup.model';
import { AuthService } from 'src/app/core/services/auth.service';

@Component({
  selector: 'app-contact-detail',
  templateUrl: './contact-detail.component.html',
  styleUrls: ['./contact-detail.component.scss']
})
export class ContactDetailComponent implements OnInit {
  @HostBinding('class.child-view') classChildView: Host = true;

  @ViewChild('contactTabset', {static: true})
  contactTabset: NgbTabset;

  @ViewChild('createFollowupModal', {static: true})
  createFollowupModalElement: ElementRef;

  public isLoading = true;
  public canSave = false;
  public isSaving = false;
  public contactId: number;
  public contact: Contact;
  public mainForm: FormGroup;
  public followups: Followup[];
  public createFollowupForm: FormGroup;
  public followupDateRange: any;
  public availableFollowupHours: number[];
  public availableFollowupMinutes: number[];
  public $users: Observable<any[]>;
  public isFuture: Function = isFuture; // Provide function in template

  constructor(
    private activatedRoute: ActivatedRoute,
    private contactsService: ContactsService,
    private toaster: ToastrService,
    private contactsQuicksearchService: ContactsQuicksearchService,
    private fb: FormBuilder,
    private modal: NgbModal,
    private usersService: UsersService,
    private followupsService: FollowupsService,
    private authService: AuthService // Needed in template
  ) { }

  ngOnInit() {
    this.activatedRoute.params.subscribe(params => {
      this.contactId = +params['id'];
      this.loadDetails();
    });

    this.availableFollowupHours = Array.from(Array(24).keys());
    this.availableFollowupMinutes = [0, 15, 30, 45];

    this.$users = this.usersService.getUsers().pipe(
      map(users => {
        users.sort((a, b) => a.lastName.localeCompare(b.lastName));

        users = users.map((user: any) => {
          user.name = user.firstName + ' ' + user.lastName;
          return user;
        });

        return users;
      })
    );
  }

  loadDetails() {
    this.contactsService.getContact(this.contactId).subscribe(
      (contact: Contact) => {
        this.contact = contact;
        this.loadFollowups();
        this.transformDetails();

        if (this.mainForm) {
          this.resetMainForm();
        }

        if (this.contactTabset) {
          this.contactTabset.select('tab-contact-main');
        }
      },
      error => {
        this.toaster.error(`Kontaktdaten konnten nicht geladen werden: ${error.status} – ${error.statusText}.`, 'Fehler');
        this.contactId = undefined;
      }
    );
  }

  transformDetails() {
    this.contact.country = (<ContactCountry>this.contact.country) ? (<ContactCountry>this.contact.country).key : null;
    this.contact.interests = (<ContactInterest[]>this.contact.interests).map((interest: ContactInterest) => interest.id);
  }

  mainFormReady(mainForm: FormGroup) {
    this.mainForm = mainForm;

    this.mainForm.patchValue(this.contact);

    this.mainForm.statusChanges.subscribe(status => {
      this.canSave = status === 'VALID';
    });
  }

  saveMainForm() {
    this.isSaving = true;
    this.contactsService.updateContact({
      ...this.mainForm.value,
      phone: this.mainForm.value.phone || null
    }).subscribe(updatedContact => {
      this.contact = updatedContact;
      this.contactsQuicksearchService.updateInResults(this.contact);
      this.transformDetails();
      this.resetMainForm();
      this.toaster.success('Kontakt wurde aktualisiert.');
      this.isSaving = false;
    }, error => {
      if (error.status === 409) {
        this.toaster.error(`Kontakt konnte nicht aktualisiert werden, da bereits ein Kontakt mit dieser E-Mail-Adresse existiert.`, 'Fehler');
      } else {
        this.toaster.error(`Kontakt konnte nicht aktualisiert werden: ${error.status} – ${error.statusText}.`, 'Fehler');
      }
      
      this.isSaving = false;
    });
  }

  resetMainForm() {
    this.mainForm.reset(this.contact);
  }

  setActivityStatusPatron(status: boolean) {
    this.contact.activityStatusPatron = status;
  }

  setActivityStatusDonator(status: boolean) {
    this.contact.activityStatusDonator = status;
  }

  resetHotlead() {
    this.contactsService.resetHotlead(this.contact.id).subscribe(
      () => {
        this.contact.hotleadDeadline = null;
      },
      error => {
        this.toaster.error(`Hot-Lead-Markierung konnte nicht entfernt werden: ${error.status} – ${error.statusText}.`, 'Fehler');
        this.followups = [];
      }
    );
  }

  loadFollowups() {
    this.followupsService.getFollowups(this.contact.id).subscribe(
      followups => {
        this.followups = followups;
      },
      error => {
        this.toaster.error(`Wiedervorlage-Daten konnten nicht geladen werden: ${error.status} – ${error.statusText}.`, 'Fehler');
        this.followups = [];
      }
    );
  }

  openCreateFollowupModal() {
    const now = new Date();

    this.followupDateRange = {
      min: isoDateStringToNgbDate(format(now)),
      max: isoDateStringToNgbDate(format(addYears(now, 1)))
    };

    const initialFollowupIsoDate = format(addDays(now, 1), 'YYYY-MM-DD');

    this.createFollowupForm = this.fb.group({
      contact: [this.contact.id],
      date: [initialFollowupIsoDate, [Validators.required]],
      hour: [8],
      minutes: [0],
      assignedTo: [null],
      note: [null]
    }, {
      validator: this.futureTimestampValidator.bind(this)
    });

    this.modal.open(this.createFollowupModalElement, { backdrop: 'static' }).result.then(result => {
      // Close
    }, reason => {
      // Dismiss
    });
  }

  futureTimestampValidator(formGroup: FormGroup): { [key: string]: boolean } | null {
    const date = this.getDateFromFollowupForm(formGroup);

    if (!isFuture(date)) {
      this.setTimeNotInFuture(formGroup, true);
      return { 'dateNotInFuture': true };
    } else {
      this.setTimeNotInFuture(formGroup, false);
      return null;
    }
  }

  getDateFromFollowupForm(formGroup: FormGroup): Date {
    const date = formGroup.get('date').value;
    const hour = formGroup.get('hour').value;
    const minutes = formGroup.get('minutes').value;

    return setMinutes(setHours(date, hour), minutes);
  }

  setTimeNotInFuture(formGroup: FormGroup, notInFuture: boolean) {
    const hour = formGroup.get('hour');
    const minutes = formGroup.get('minutes');

    if (notInFuture) {
      hour.markAsTouched();
      minutes.markAsTouched();

      hour.setErrors({ notInFuture });
      minutes.setErrors({ notInFuture });
    } else {
      hour.setErrors(null);
      minutes.setErrors(null);
    }
  }

  createFollowup(closeModal: Function) {
    this.createFollowupForm.updateValueAndValidity();
    if (!this.createFollowupForm.valid) {
      return;
    }

    const followupFormData = this.createFollowupForm.value;

    const followup: Partial<Followup> = {
      assignedTo: followupFormData.assignedTo,
      deadline: format(this.getDateFromFollowupForm(this.createFollowupForm)),
      note: followupFormData.note
    };

    this.followupsService.createFollowup(followupFormData.contact, followup).subscribe(
      followups => {
        this.followups = followups;
        closeModal();
        this.toaster.success('Wiedervorlage wurde gespeichert.');
      }, error => {
        this.toaster.error(`Wiedervorlage konnte nicht gespeichert werden: ${error.status} – ${error.statusText}.`, 'Fehler');
      }
    );
  }

  deleteFollowup(followupId: number) {
    this.followupsService.deleteFollowup(this.contact.id, followupId).subscribe(
      () => {
        this.followups = this.followups.filter(f => f.id !== followupId);
      }, error => {
        this.toaster.error(`Wiedervorlage konnte nicht gelöscht werden: ${error.status} – ${error.statusText}.`, 'Fehler');
      }
    );
  }
}
