import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { NgbModule, NgbDatepickerConfig, NgbDateAdapter,
  NgbDateParserFormatter } from '@ng-bootstrap/ng-bootstrap';
import { RouterModule } from '@angular/router';
import { FontAwesomeModule } from '@fortawesome/angular-fontawesome';
import { library } from '@fortawesome/fontawesome-svg-core';
import { fas } from '@fortawesome/free-solid-svg-icons';
import { fab } from '@fortawesome/free-brands-svg-icons';
import { MinRoleDirective } from './directives/min-role.directive';
import { NgbDateISOStringAdapter } from './misc/ngb-date-iso-string-adapter';
import { NgbDateLocaleParserFormatter } from './misc/ngb-date-locale-parser-formatter';
import { NgSelectModule, NgSelectConfig } from '@ng-select/ng-select';
import { VirtualScrollerModule } from 'ngx-virtual-scroller';
import { DistanceInWordsToNowPipe } from './pipes/distance-in-words-to-now.pipe';
import { NgxDatatableModule } from '@swimlane/ngx-datatable';
import { DragDropModule } from '@angular/cdk/drag-drop';
import { ConfirmDialogComponent } from './components/confirm-dialog/confirm-dialog.component';
import { ConfirmDialogService } from './services/confirm-dialog.service';

library.add(fas);

@NgModule({
  imports: [
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    NgbModule,
    RouterModule,
    FontAwesomeModule,
    NgSelectModule,
    VirtualScrollerModule,
    DragDropModule
  ],
  declarations: [
    MinRoleDirective,
    DistanceInWordsToNowPipe,
    ConfirmDialogComponent
  ],
  entryComponents: [
    ConfirmDialogComponent
  ],
  providers: [
    ConfirmDialogService,
    { provide: NgbDateAdapter, useClass: NgbDateISOStringAdapter },
    { provide: NgbDateParserFormatter, useClass: NgbDateLocaleParserFormatter }
  ],
  exports: [
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    NgbModule,
    RouterModule,
    FontAwesomeModule,
    MinRoleDirective,
    NgSelectModule,
    VirtualScrollerModule,
    DistanceInWordsToNowPipe,
    NgxDatatableModule,
    DragDropModule
  ]
})
export class SharedModule {
  constructor(
    ngbDatepickerConfig: NgbDatepickerConfig,
    ngSelectConfig: NgSelectConfig
  ) {
    ngSelectConfig.notFoundText = 'Keinen passenden Eintrag gefunden.';
  }
}
