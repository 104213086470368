import { Component, OnInit } from '@angular/core';
import { FormGroup } from '@angular/forms';
import { ToastrService } from 'ngx-toastr';
import { ContactsService } from '../../services/contacts.service';
import { Router } from '@angular/router';

@Component({
  selector: 'app-contact-create',
  templateUrl: './contact-create.component.html',
  styleUrls: ['./contact-create.component.scss']
})
export class ContactCreateComponent implements OnInit {
  public form: FormGroup;
  public contactDefaultValues: any;
  public canSave = false;
  public isSaving = false;

  constructor(
    private contactsService: ContactsService,
    private toaster: ToastrService,
    private router: Router
  ) {}

  ngOnInit() {
    this.contactDefaultValues = {
      country: 'de'
    };
  }

  formReady(form: FormGroup) {
    this.form = form;

    this.form.patchValue(this.contactDefaultValues);

    this.form.statusChanges.subscribe(status => {
      this.canSave = status === 'VALID';
    });
  }

  save() {
    this.isSaving = true;
    this.contactsService.createContact(this.form.value).subscribe(createdContact => {
      this.toaster.success('Neuer Kontakt wurde angelegt.');
      this.router.navigate([`/contacts/detail/${createdContact.id}`]);
    }, error => {
      if (error.status === 409) {
        this.toaster.error(`Kontakt konnte nicht angelegt werden, da bereits ein Kontakt mit dieser E-Mail-Adresse existiert.`, 'Fehler');
      } else {
        this.toaster.error(`Kontakt konnte nicht angelegt werden: ${error.status} – ${error.statusText}.`, 'Fehler');
      }
      
      this.isSaving = false;
    });
  }

  cancel() {
    this.form.reset(this.contactDefaultValues);
  }
}
