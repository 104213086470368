import { Component, OnInit, ElementRef, ViewChild, Input, TemplateRef, OnChanges } from '@angular/core';
import { CommunicationEventsService } from '../../services/communication-events.service';
import { forkJoin } from 'rxjs';
import { ToastrService } from 'ngx-toastr';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { CommunicationEvent } from 'src/app/shared/models/communication-event.model';
import { FormBuilder, Validators, FormGroup } from '@angular/forms';
import { isoDateStringToNgbDate } from 'src/app/shared/misc/date.functions';
import { format, getHours, getMinutes, setMinutes, setHours } from 'date-fns';
import { CommunicationChannel } from 'src/app/shared/models/communication-event-type.model';

@Component({
  selector: 'app-communication-events',
  templateUrl: './communication-events.component.html',
  styleUrls: ['./communication-events.component.scss']
})
export class CommunicationEventsComponent implements OnInit, OnChanges {
  @Input()
  public contactId: number;

  @ViewChild('dateCellTemplate', {static: true}) dateCellTemplate: TemplateRef<any>;
  @ViewChild('typeCellTemplate', {static: true}) typeCellTemplate: TemplateRef<any>;
  @ViewChild('statusCellTemplate', {static: true}) statusCellTemplate: TemplateRef<any>;
  @ViewChild('addEditEventModal', {static: true}) addEditEventModalElement: ElementRef;
  @ViewChild('deleteEventModal', {static: true}) deleteEventModalElement: ElementRef;

  public historyEvents = [];
  public availableChannels: CommunicationChannel[];
  public columns: any;
  public messages: any;
  public totalCount: number;
  public orderBy: any;
  public pageSize = 20;
  public pageNumber = 0;
  public editMode: boolean;
  public addEditEventForm: FormGroup;
  public canDelete: boolean;
  public eventToDeleteId: number;
  public maximumEventDate: any;
  public availableEventHours: number[];
  public availableEventMinutes: number[];

  constructor(
    private communicationEventsService: CommunicationEventsService,
    private modal: NgbModal,
    private fb: FormBuilder,
    private toaster: ToastrService
  ) {}

  ngOnInit() {
    this.maximumEventDate = isoDateStringToNgbDate(format(new Date()));
    this.availableEventHours = Array.from(Array(24).keys());
    this.availableEventMinutes = [0, 5, 10, 15, 20, 25, 30, 35, 40, 45, 50, 55];

    this.setupTable();
  }

  ngOnChanges() {
    forkJoin(
      this.communicationEventsService.getHistoryByContact(this.contactId, 0, this.pageSize),
      this.communicationEventsService.getChannels()
    ).subscribe(
      ([historyEventsPage, channels]) => {
        this.historyEvents = historyEventsPage.historyEvents;
        this.totalCount = historyEventsPage.count;
        this.availableChannels = channels;
      },
      error => this.toaster.error(`Daten konnten nicht geladen werden: ${error.status} – ${error.statusText}.`, 'Fehler')
    );
  }

  setupTable() {
    this.columns = [
      {
        prop: 'date',
        name: 'Datum',
        flexGrow: 0,
        minWidth: 150,
        cellTemplate: this.dateCellTemplate
      }, {
        prop: 'typeKey',
        name: 'Art',
        flexGrow: 0,
        minWidth: 40,
        cellTemplate: this.typeCellTemplate
      }, {
        prop: 'text',
        name: 'Text',
        flexGrow: 0.8
      }, {
        prop: 'status',
        name: 'Status',
        flexGrow: 0.2,
        cellTemplate: this.statusCellTemplate
      }
    ];

    this.messages = {
      emptyMessage: 'Keine Daten vorhanden.',
      totalMessage: 'Ereignis(se) insgesamt.'
    };
  }

  setPage(pageInfo: any) {
    this.pageNumber = pageInfo.offset;

    this.communicationEventsService.getHistoryByContact(this.contactId, this.pageNumber * this.pageSize, this.pageSize).subscribe(
      historyEventsPage => {
        this.historyEvents = historyEventsPage.historyEvents;
        this.totalCount = historyEventsPage.count;
      },
      error => this.toaster.error(`Kommunikationshistorie konnte nicht geladen werden: ${error.status} – ${error.statusText}.`, 'Fehler')
    );
  }

  onActivate(event) {
    if (event.type === 'click' && event.row.typeKey !== 'mailchimp') {
      event.cellElement.blur();
      this.openAddEditEventModal(event.row);
    }
  }

  openAddEditEventModal(event?: CommunicationEvent) {
    const now = new Date();
    const initialEventIsoDate = format(now, 'YYYY-MM-DD');

    this.addEditEventForm = this.fb.group({
      id: [event ? event.id : null],
      type: [null, [Validators.required]],
      date: [initialEventIsoDate, [Validators.required]],
      hour: [getHours(now)],
      minutes: [getMinutes(now) - getMinutes(now) % 5],
      text: ['', [Validators.required]]
    });

    if (event) {
      // Edit
      this.editMode = true;

      this.addEditEventForm.patchValue({
        ...event,
        type: event.typeKey,
        date: format(event.date, 'YYYY-MM-DD'),
        hour: getHours(event.date),
        minutes: getMinutes(event.date)
      });
    } else {
      // Add
      this.editMode = false;
    }

    this.modal.open(this.addEditEventModalElement, { backdrop: 'static' }).result.then(result => {
      // Close
    }, reason => {
      // Dismiss
    });
  }

  getDateFromEventForm(): Date {
    const date = this.addEditEventForm.get('date').value;
    const hour = this.addEditEventForm.get('hour').value;
    const minutes = this.addEditEventForm.get('minutes').value;

    return setMinutes(setHours(date, hour), minutes);
  }

  saveEvent(closeModal: Function) {
    const eventFormData = this.addEditEventForm.value;

    const newEvent: Partial<CommunicationEvent> = {
      id: eventFormData.id,
      contactId: this.contactId,
      typeKey: eventFormData.type,
      date: format(this.getDateFromEventForm()),
      text: eventFormData.text
    };

    this.communicationEventsService.saveEvent(newEvent).subscribe(() => {
      closeModal();
      this.toaster.success('Kommunikationsereignis wurde gespeichert.');
      this.reloadEvents();
    }, error => this.toaster.error(`Kommunikationsereignis konnte nicht gespeichert werden: ${error.status} – ${error.statusText}.`, 'Fehler')); // tslint:disable

    closeModal();
  }

  openDeleteEventModal(eventToDeleteId: number) {
    this.eventToDeleteId = eventToDeleteId;

    this.modal.open(this.deleteEventModalElement, { backdrop: 'static' }).result.then(result => {
      // Close
      this.eventToDeleteId = undefined;
    }, reason => {
      // Dismiss
      this.eventToDeleteId = undefined;
    });
  }

  deleteEvent(closeModal: Function) {
    this.communicationEventsService.deleteEvent(this.eventToDeleteId).subscribe(() => {
      closeModal();
      this.toaster.success('Kommunikationsereignis wurde gelöscht.');
      this.reloadEvents();
    }, error => this.toaster.error(`Kommunikationsereignis konnte nicht gelöscht werden: ${error.status} – ${error.statusText}.`, 'Fehler'));
  }

  reloadEvents() {
    this.setPage({ offset: 0 });
  }
}
