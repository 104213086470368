import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { environment } from '../../../environments/environment';
import { Observable } from 'rxjs';
import { CommunicationEvent } from 'src/app/shared/models/communication-event.model';
import { CommunicationChannel } from 'src/app/shared/models/communication-event-type.model';

@Injectable({
  providedIn: 'root'
})
export class CommunicationEventsService {
  constructor(
    private httpClient: HttpClient
  ) {}

  getHistoryByContact(contactId: number, skip = 0, take = 50): Observable<any> {
    // tslint:disable-next-line:max-line-length
    return this.httpClient.get<any[]>(`${environment.api.baseUrl}communication/events/contacts/${contactId}/history?skip=${skip}&take=${take}`);
  }

  getChannels(): Observable<CommunicationChannel[]> {
    return this.httpClient.get<any[]>(`${environment.api.baseUrl}communication/events/channels`);
  }

  saveEvent(partialEvent: Partial<CommunicationEvent>): Observable<CommunicationEvent> {
    if (partialEvent.id) {
      // Update
      return this.httpClient.put<CommunicationEvent>(`${environment.api.baseUrl}communication/events/${partialEvent.id}`, partialEvent);
    } else {
      // Create
      return this.httpClient.post<CommunicationEvent>(`${environment.api.baseUrl}communication/events`, partialEvent);
    }
  }

  deleteEvent(id: number): Observable<any> {
    return this.httpClient.delete<CommunicationEvent>(`${environment.api.baseUrl}communication/events/${id}`);
  }
}
