import { Component, OnInit, ViewChild, ElementRef, OnChanges } from '@angular/core';
import { CommunicationListsService } from '../../services/communication-lists.service';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ToastrService } from 'ngx-toastr';
import { CommunicationList } from 'src/app/shared/models/communication-list.model';
import { forkJoin } from 'rxjs';
import { Search } from 'src/app/shared/models/search.model';
import { SearchService } from 'src/app/contacts/services/search.service';

@Component({
  selector: 'app-communication-lists',
  templateUrl: './communication-lists.component.html',
  styleUrls: ['./communication-lists.component.scss']
})
export class CommunicationListsComponent implements OnInit {
  @ViewChild('addEditListModal', {static: true})
  addEditListModalElement: ElementRef;

  @ViewChild('deleteListModal', {static: true})
  deleteListModalElement: ElementRef;

  public lists: CommunicationList[] = [];
  public availableSearches: Search[] = [];
  public editMode: boolean;
  public addEditListForm: FormGroup;
  public listToDelete: CommunicationList;

  constructor(
    private communicationListsService: CommunicationListsService,
    private searchService: SearchService,
    private modal: NgbModal,
    private fb: FormBuilder,
    private toaster: ToastrService
  ) {}

  ngOnInit() {
    forkJoin(
      this.communicationListsService.getLists(),
      this.searchService.getSearches()
    ).subscribe(
      ([lists, availableSearches]) => {
        this.lists = lists;
        this.availableSearches = availableSearches;
      },
      error => this.toaster.error(`Daten konnten nicht geladen werden: ${error.status} – ${error.statusText}.`, 'Fehler')
    );
  }

  openAddEditListModal(list?: CommunicationList) {
    this.addEditListForm = this.fb.group({
      id: [null],
      contactSearchId: [null, [Validators.required]],
      name: [null, [Validators.required]],
      active: [true]
    });

    if (list) {
      // Edit
      this.editMode = true;

      this.addEditListForm.patchValue(list);
      this.addEditListForm.get('contactSearchId').setValue(list.contactSearch.id);
    } else {
      // Add
      this.editMode = false;
    }

    this.modal.open(this.addEditListModalElement, { backdrop: 'static', size: 'lg' }).result.then(result => {
      // Close
    }, reason => {
      // Dismiss
    });
  }

  saveList(closeModal: Function) {
    const newList = this.addEditListForm.value;

    this.communicationListsService.saveList(newList).subscribe(() => {
      this.reloadLists();
      this.toaster.success('Verteilerliste wurde gespeichert.');
      closeModal();
    }, error => this.toaster.error(`Verteilerliste konnte nicht gespeichert werden: ${error.status} – ${error.statusText}.`, 'Fehler'));

    closeModal();
  }

  openDeleteListModal(list: CommunicationList) {
    this.listToDelete = list;

    const deleteModal = this.modal.open(this.deleteListModalElement, { backdrop: 'static' }).result.then(result => {
      // Close
    }, reason => {
      // Dismiss
      this.listToDelete = undefined;
    });
  }

  deleteList(closeModal: Function) {
    this.communicationListsService.deleteList(this.listToDelete.id).subscribe(() => {
      this.reloadLists();
      this.toaster.success('Verteilerliste wurde gelöscht.');
    }, error => this.toaster.error(`Verteilerliste konnte nicht gelöscht werden: ${error.status} – ${error.statusText}.`, 'Fehler'));

    this.listToDelete = undefined;
    closeModal();
  }

  reloadLists() {
    this.communicationListsService.getLists().subscribe(
      lists => this.lists = lists,
      error => this.toaster.error(`Verteilerlisten konnten nicht geladen werden: ${error.status} – ${error.statusText}.`, 'Fehler')
    );
  }
}
