import { Component, OnInit, ChangeDetectorRef, AfterViewInit } from '@angular/core';
import { FormGroup, FormBuilder } from '@angular/forms';
import { ActivatedRoute } from '@angular/router';
import { ChangeEvent } from 'ngx-virtual-scroller';
import { ContactsQuicksearchService } from '../../services/contacts-quicksearch.service';
import { Search } from 'src/app/shared/models/search.model';
import { SearchService } from '../../services/search.service';
import { ToastrService } from 'ngx-toastr';
import { ContactsService } from '../../services/contacts.service';

@Component({
  selector: 'app-contact-detail-master',
  templateUrl: './contact-detail-master.component.html',
  styleUrls: ['./contact-detail-master.component.scss']
})
export class ContactDetailMasterComponent implements OnInit, AfterViewInit {
  public quicksearchForm: FormGroup;
  public selectSearchForm: FormGroup;
  public savedSearches: Search[];
  public canSearch = false;
  public hasSearched = false;

  constructor(
    private fb: FormBuilder,
    private searchService: SearchService,
    private contactsQuicksearchService: ContactsQuicksearchService,
    private contactsService: ContactsService,
    public activatedRoute: ActivatedRoute, // Needed in template
    private changeDetectorRef: ChangeDetectorRef,
    private toaster: ToastrService
  ) {}

  ngOnInit() {
    this.contactsQuicksearchService.clearResults();

    this.quicksearchForm = this.fb.group({
      searchText: ['']
    });

    this.quicksearchForm.get('searchText').valueChanges.subscribe(searchText => {
      if (searchText) {
        this.selectSearchForm.get('selectedSavedSearch').setValue(null, { emitEvent: false });

        this.canSearch = searchText.trim().length >= 3;
      } else {
        this.canSearch = false;
      }
    });

    this.selectSearchForm = this.fb.group({
      selectedSavedSearch: [null]
    });

    this.selectSearchForm.get('selectedSavedSearch').valueChanges.subscribe(selectedSavedSearch => {
      if (selectedSavedSearch !== null) {
        this.quicksearchForm.get('searchText').setValue('', { emitEvent: false });
        this.performSavedSearch(selectedSavedSearch);
      }
    });

    this.searchService.getSearches().subscribe(
      (searches) => {
        this.savedSearches = [
          { id: null, name: '–' },
          ...searches
        ];
      },
    );

    this.contactsService.contactDeleted.subscribe(contactId => this.removeFromSearchResults(contactId));
  }

  ngAfterViewInit() {
    this.changeDetectorRef.detectChanges();
  }

  searchByText(text: string) {
    this.hasSearched = false;
    this.contactsQuicksearchService.clearResults();
    this.contactsQuicksearchService.searchByText(text).subscribe(() => {
      this.hasSearched = true;
    }, error => this.displaySearchError(error));
  }

  performSavedSearch(id: number) {
    this.hasSearched = false;
    this.contactsQuicksearchService.clearResults();
    this.contactsQuicksearchService.performSavedSearch(id).subscribe(() => {
      this.hasSearched = true;
    }, error => this.displaySearchError(error));
  }

  fetchMore(event: ChangeEvent) {
    let observable$: any;

    if (event.end === -1 || event.end !== this.searchResults.length - 1) {
      return;
    }

    if (this.selectSearchForm.get('selectedSavedSearch').value === null) {
      observable$ = this.contactsQuicksearchService.searchByText();
    } else {
      observable$ = this.contactsQuicksearchService.performSavedSearch();
    }

    observable$.subscribe(() => {
      // Successful
    }, error => this.displaySearchError(error));
  }

  get searchResults() {
    return this.contactsQuicksearchService.searchResults;
  }

  displaySearchError(error: any) {
    this.toaster.error(`Suche fehlgeschlagen: ${error.status} – ${error.statusText}.`, 'Fehler');
  }

  removeFromSearchResults(contactId: number) {
    const index = this.contactsQuicksearchService.searchResults.findIndex(c => c.id === contactId);

    if (index !== -1) {
      this.contactsQuicksearchService.searchResults.splice(index, 1);
    }
  }
}
