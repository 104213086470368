import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { User } from '../../shared/models/user.model';
import { Role } from '../../shared/models/role.model';
import { Observable } from 'rxjs';
import { environment } from '../../../environments/environment';

@Injectable({
  providedIn: 'root'
})
export class UsersService {
  constructor(
    private httpClient: HttpClient
  ) {}

  getUser(id?: number): Observable<User> {
    return this.httpClient.get<User>(`${environment.api.baseUrl}auth/users/${id}`);
  }

  getUsers(includeAdmins: boolean = false): Observable<User[]> {
    return this.httpClient.get<User[]>(`${environment.api.baseUrl}auth/users?includeAdmins=${includeAdmins}`);
  }

  saveUser(partialUser: Partial<User>): Observable<User> {
    if (partialUser.id) {
      // Update
      return this.httpClient.put<User>(`${environment.api.baseUrl}auth/users/${partialUser.id}`, partialUser);
    } else {
      // Create
      return this.httpClient.post<User>(`${environment.api.baseUrl}auth/users`, partialUser);
    }
  }

  getRoles(): Observable<Role[]> {
    return this.httpClient.get<Role[]>(`${environment.api.baseUrl}auth/users/roles`);
  }
}
