import { Component, OnInit } from '@angular/core';
import { Contact } from 'src/app/shared/models/contact.model';
import { isFuture } from 'date-fns';
import { ContactsService } from 'src/app/contacts/services/contacts.service';

@Component({
  selector: 'app-hotleads-portlet',
  templateUrl: './hotleads-portlet.component.html',
  styleUrls: ['./hotleads-portlet.component.scss']
})
export class HotleadsPortletComponent implements OnInit {
  public contacts: Contact[];
  public isFuture: Function = isFuture;

  constructor(
    private contactsService: ContactsService
  ) { }

  ngOnInit() {
    this.contactsService.getHotleads().subscribe(contacts => this.contacts = contacts);
  }
}
