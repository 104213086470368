import { Injectable } from '@angular/core';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { ConfirmDialogComponent } from '../components/confirm-dialog/confirm-dialog.component';

@Injectable()
export class ConfirmDialogService {
  constructor(
    private modal: NgbModal
  ) {}

  public async confirm({
    title = 'Bestätigung', message = 'Sind Sie sicher?',
    confirmText = 'Ok', cancelText = 'Abbrechen', isDangerous = false,
    confirmString
  }: {
    title?: string, message?: string, confirmText?: string, cancelText?: string,
    isDangerous?: boolean, confirmString?: { label: string, string: string }
  }): Promise<Boolean> {
    const modalRef = await this.modal.open(ConfirmDialogComponent);

    modalRef.componentInstance.title = title;
    modalRef.componentInstance.message = message;
    modalRef.componentInstance.confirmText = confirmText;
    modalRef.componentInstance.cancelText = cancelText;
    modalRef.componentInstance.isDangerous = isDangerous;
    modalRef.componentInstance.confirmString  = confirmString;

    return modalRef.result;
  }
}
