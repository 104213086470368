import { Component, OnInit, Input } from '@angular/core';

@Component({
  selector: 'app-contact-communication',
  templateUrl: './contact-communication.component.html',
  styleUrls: ['./contact-communication.component.scss']
})
export class ContactCommunicationComponent implements OnInit {
  @Input()
  public contactId: number;

  constructor() {}

  ngOnInit() {
  }

}
