import { Injectable, EventEmitter } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { environment } from 'src/environments/environment';
import { Observable } from 'rxjs';
import { Followup } from 'src/app/shared/models/followup.model';
import { map, tap } from 'rxjs/operators';
import { parse } from 'date-fns';

@Injectable({
  providedIn: 'root'
})
export class FollowupsService {
  public followupsChanged: EventEmitter<null>;

  constructor(
    private httpClient: HttpClient
  ) {
    this.followupsChanged = new EventEmitter();
  }

  getFollowups(contactId: number): Observable<Followup[]> {
    return this.httpClient.get<Followup[]>(`${environment.api.baseUrl}contacts/${contactId}/followups`).pipe(
      map(this.transformFollowups)
    );
  }

  getMyFollowups(options: {
    expiredOnly: boolean;
    assignedToMeOnly: boolean;
    disableLoadingSpinner: boolean;
  } = {
    expiredOnly: false,
    assignedToMeOnly: false,
    disableLoadingSpinner: false
  }): Observable<Followup[]> {
    let headers: HttpHeaders = new HttpHeaders();

    if (options.disableLoadingSpinner) {
      headers = headers.append('Disable-Loading-Spinner', '');
    }

    return this.httpClient.get<Followup[]>(
      `${environment.api.baseUrl}contacts/my-followups?expiredOnly=${options.expiredOnly}&assignedToMeOnly=${options.assignedToMeOnly}`,
      { headers }
    );
  }

  createFollowup(contactId: number, followup: Partial<Followup>): Observable<Followup[]> {
    return this.httpClient.post<Followup[]>(`${environment.api.baseUrl}contacts/${contactId}/followups`, followup).pipe(
      tap(() => this.followupsChanged.emit())
    );
  }

  deleteFollowup(contactId: number, followupId: number): Observable<void> {
    return this.httpClient.delete<void>(`${environment.api.baseUrl}contacts/${contactId}/followups/${followupId}`).pipe(
      tap(() => this.followupsChanged.emit())
    );
  }

  private transformFollowups(followups: Followup[]) {
    return followups.map(followup => {
      followup.deadline = parse(followup.deadline);
      return followup;
    });
  }
}
